/* eslint-disable react-refresh/only-export-components */
import React, { createContext, ReactNode, useContext, useReducer } from "react";

export interface PaymentForm {
  id: number;
  name: string;
}

interface Complement {
  id: number;
  name: string;
  quantity: number;
}

export interface Product {
  id: string;
  name: string;
  price: number;
  quantity: number;
  image?: string;
  department: number;
  description?: string;
  complements?: Complement[];
}

export interface Department {
  id: number;
  name: string;
}

interface BusinessHour {
  open: string;
  close: string;
  isOpen: boolean;
}

interface BusinessHours {
  monday: BusinessHour;
  tuesday: BusinessHour;
  wednesday: BusinessHour;
  thursday: BusinessHour;
  friday: BusinessHour;
  saturday: BusinessHour;
  sunday: BusinessHour;
}

export interface Store {
  id: string;
  name: string;
  address: string;
  phone: string;
  businessHours: BusinessHours;
  allow_delivery: boolean;
  allow_pickup: boolean;
  delivery_price: number;
}

interface CatalogState {
  store: Store | null;
  cart: Product[];
  cartTotal: number;
  cartItemsCount: number;
  isLoading: boolean;
  departments: Department[];
  products: Product[];
  paymentForms: PaymentForm[];
  activeWindow: "catalog" | "cart" | "checkout";
}

type CatalogAction =
  | { type: "SET_STORE"; payload: Store }
  | { type: "SET_DEPARTMENTS"; payload: Department[] }
  | { type: "SET_PRODUCTS"; payload: Product[] }
  | { type: "SET_PAYMENT_FORMS"; payload: PaymentForm[] }
  | { type: "ADD_TO_CART"; payload: Product }
  | { type: "REMOVE_FROM_CART"; payload: string }
  | { type: "CLEAR_CART" }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_ACTIVE_WINDOW"; payload: "catalog" | "cart" | "checkout" };

const initialState: CatalogState = {
  store: {
    id: "",
    address: "",
    name: "",
    phone: "",
    allow_delivery: false,
    allow_pickup: false,
    delivery_price: 0,
    businessHours: {
      monday: { open: "09:00", close: "18:00", isOpen: true },
      tuesday: { open: "09:00", close: "18:00", isOpen: true },
      wednesday: { open: "09:00", close: "18:00", isOpen: true },
      thursday: { open: "09:00", close: "18:00", isOpen: true },
      friday: { open: "09:00", close: "18:00", isOpen: true },
      saturday: { open: "09:00", close: "18:00", isOpen: true },
      sunday: { open: "09:00", close: "18:00", isOpen: true },
    },
  },
  cart: [],
  cartTotal: 0,
  cartItemsCount: 0,
  isLoading: true,
  departments: [],
  products: [],
  paymentForms: [],
  activeWindow: "catalog",
};

const calculateCartTotals = (cart: Product[]) => {
  const cartTotal = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const cartItemsCount = cart.reduce((total, item) => total + item.quantity, 0);
  return { cartTotal, cartItemsCount };
};

const catalogReducer = (
  state: CatalogState,
  action: CatalogAction
): CatalogState => {
  switch (action.type) {
    case "SET_STORE":
      return {
        ...state,
        store: action.payload,
      };
    case "SET_DEPARTMENTS":
      return {
        ...state,
        departments: action.payload,
      };
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.payload,
      };
    case "SET_PAYMENT_FORMS":
      return {
        ...state,
        paymentForms: action.payload,
      };

    case "ADD_TO_CART": {
      const existingItem = state.cart.find(
        (item) => item.id === action.payload.id
      );
      let newCart;

      if (existingItem) {
        newCart = state.cart.map((item) =>
          item.id === action.payload.id
            ? { ...item, quantity: action.payload.quantity }
            : item
        );
      } else {
        newCart = [...state.cart, { ...action.payload }];
      }

      const { cartTotal, cartItemsCount } = calculateCartTotals(newCart);
      return { ...state, cart: newCart, cartTotal, cartItemsCount };
    }

    case "REMOVE_FROM_CART": {
      const newCart = state.cart.filter((item) => item.id !== action.payload);
      const { cartTotal, cartItemsCount } = calculateCartTotals(newCart);
      return { ...state, cart: newCart, cartTotal, cartItemsCount };
    }

    case "CLEAR_CART":
      return {
        ...state,
        cart: [],
        cartTotal: 0,
        cartItemsCount: 0,
      };

    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };

    case "SET_ACTIVE_WINDOW":
      return {
        ...state,
        activeWindow: action.payload,
      };

    default:
      return state;
  }
};

const CatalogContext = createContext<
  | {
      state: CatalogState;
      dispatch: React.Dispatch<CatalogAction>;
    }
  | undefined
>(undefined);

export const CatalogProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(catalogReducer, initialState);

  return (
    <CatalogContext.Provider value={{ state, dispatch }}>
      {children}
    </CatalogContext.Provider>
  );
};

export const useCatalog = () => {
  const context = useContext(CatalogContext);
  if (context === undefined) {
    throw new Error("useCatalog must be used within a CatalogProvider");
  }
  return context;
};

export const useCatalogActions = () => {
  const { dispatch, state } = useCatalog();

  return {
    setStore: (store: Store) => dispatch({ type: "SET_STORE", payload: store }),
    addToCart: (product: Product) =>
      dispatch({ type: "ADD_TO_CART", payload: product }),
    removeFromCart: (productId: string) =>
      dispatch({ type: "REMOVE_FROM_CART", payload: productId }),
    clearCart: () => dispatch({ type: "CLEAR_CART" }),
    setLoading: (isLoading: boolean) =>
      dispatch({ type: "SET_LOADING", payload: isLoading }),
    isStoreOpen: () => {
      const currentDate = new Date();
      const currentWeekName = currentDate.toLocaleDateString("pt-BR", {
        weekday: "long",
      });
      const currentHour = currentDate.getHours();
      const storeBusinessHours =
        state.store?.businessHours[currentWeekName as keyof BusinessHours];
      return (
        storeBusinessHours?.isOpen &&
        currentHour >= parseInt(storeBusinessHours.open) &&
        currentHour < parseInt(storeBusinessHours.close)
      );
    },
    getDepartmentsWithProducts: () => {
      return state.departments.filter((dept) =>
        state.products.some((product) => product.department === dept.id)
      );
    },
    getProductsByDepartment: (departmentId: number) => {
      return state.products.filter(
        (product) => product.department === departmentId
      );
    },
    getProductById: (productId: string) => {
      return state.products.find((product) => product.id === productId);
    },
    getProductQuantity: (productId: string) => {
      const product = state.cart.find((item) => item.id === productId);
      return product?.quantity || 0;
    },
    setActiveWindow: (window: "catalog" | "cart" | "checkout") => {
      dispatch({ type: "SET_ACTIVE_WINDOW", payload: window });
    },
    loadStoredCatalogData: () => {
      dispatch({ type: "SET_LOADING", payload: true });
      const storedData = localStorage.getItem("catalogo_data");
      if (storedData) {
        const { store, departments, products, paymentForms } =
          JSON.parse(storedData);

        if (store) {
          dispatch({ type: "SET_STORE", payload: store });
        }
        if (departments) {
          dispatch({ type: "SET_DEPARTMENTS", payload: departments });
        }
        if (products) {
          dispatch({ type: "SET_PRODUCTS", payload: products });
        }
        if (paymentForms) {
          dispatch({ type: "SET_PAYMENT_FORMS", payload: paymentForms });
        }
      }
      dispatch({ type: "SET_LOADING", payload: false });
    },
    getPaymentFormById: (id: number) => {
      return state.paymentForms.find((form) => form.id === id);
    },
  };
};
