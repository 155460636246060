import { Add, Delete, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  CardMedia,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { Product } from "../../context";
import PlaceholderImage from "../PlaceholderImage";
import ProductModal from "./ProductModal";

const ProductModalDesktop: React.FC<{
  product: Product;
  open: boolean;
  setOpen: (open: boolean) => void;
  setSelectedProduct: (product: Product | null) => void;
}> = (props) => {
  return (
    <ProductModal {...props}>
      {({
        imageLoading,
        setImageLoading,
        quantity,
        setQuantity,
        handleClose,
        handleAddToCart,
        handleRemoveFromCart,
        productQuantity,
        disableQuantity,
      }) => (
        <Dialog
          open={props.open}
          onClose={handleClose}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              minHeight:
                props.product?.complements &&
                props.product?.complements?.length > 0
                  ? 500
                  : "unset",
            },
          }}
        >
          <DialogTitle>{props.product?.name}</DialogTitle>
          <DialogContent>
            <Box
              display="flex"
              flexDirection="row"
              sx={{
                height:
                  props.product?.complements &&
                  props.product?.complements?.length > 0
                    ? 500
                    : "100%",
              }}
            >
              <Box width={200} height={200}>
                {imageLoading && (
                  <Skeleton
                    variant="rectangular"
                    width={200}
                    height={200}
                    animation="wave"
                    sx={{ position: "absolute" }}
                  />
                )}
                {props.product?.image ? (
                  <CardMedia
                    component="img"
                    height={200}
                    image={props.product.image}
                    onLoad={() => setImageLoading(false)}
                    sx={{ display: imageLoading ? "none" : "block" }}
                  />
                ) : (
                  <PlaceholderImage
                    text={props.product?.name}
                    width={200}
                    height={200}
                    setImageLoading={setImageLoading}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1} ml={2}>
                <Box>
                  <Typography variant="h6" component="h2">
                    {props.product?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {props.product?.description}
                  </Typography>
                  <Typography variant="h6" component="h2">
                    {props.product?.price.toBRL()}
                  </Typography>
                </Box>
                <Divider />
                <Box flexGrow={1} mt={2}>
                  {props.product?.complements &&
                    props.product?.complements?.length > 0 && (
                      <>
                        <Typography
                          variant="h6"
                          textAlign="center"
                          component="h2"
                        >
                          Complementos
                        </Typography>
                        {props.product?.complements?.map((complement) => (
                          <Box
                            key={complement.id}
                            display="flex"
                            alignItems="center"
                            justifyContent="left"
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme.palette.primary.main}`,
                              borderRadius: 1,
                              padding: 0.5,
                              margin: 1,
                              "&:hover": {
                                border: (theme) =>
                                  `1px solid ${theme.palette.primary.main}`,
                                boxShadow: (theme) =>
                                  `0 0 2px 0 ${theme.palette.primary.main}`,
                                cursor: "pointer",
                                transition: "all 0.1s ease",
                              },
                            }}
                            onClick={() => {
                              console.log(complement);
                            }}
                          >
                            <Checkbox defaultChecked disableRipple />
                            <Typography variant="body1" component="p" ml={2}>
                              {complement.name}
                            </Typography>
                          </Box>
                        ))}
                      </>
                    )}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {productQuantity >= 1 && quantity === 1 ? (
                    <IconButton
                      onClick={handleRemoveFromCart}
                      sx={{
                        border: (theme) =>
                          `1px solid ${theme.palette.error.main}`,
                        color: (theme) => theme.palette.error.main,
                      }}
                    >
                      <Delete />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => setQuantity(Math.max(1, quantity - 1))}
                      sx={{
                        border: (theme) =>
                          `1px solid ${
                            disableQuantity ? "gray" : theme.palette.error.main
                          }`,
                        color: (theme) =>
                          disableQuantity ? "gray" : theme.palette.error.main,
                      }}
                      disabled={disableQuantity}
                    >
                      <Remove />
                    </IconButton>
                  )}
                  <Typography variant="h6" component="h2">
                    {quantity}
                  </Typography>
                  <IconButton
                    onClick={() => setQuantity(quantity + 1)}
                    sx={{
                      border: (theme) =>
                        `1px solid ${theme.palette.success.main}`,
                      color: (theme) => theme.palette.success.main,
                    }}
                  >
                    <Add />
                  </IconButton>
                  <Button
                    onClick={handleAddToCart}
                    variant="contained"
                    color="primary"
                  >
                    Confirmar
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </ProductModal>
  );
};

export default ProductModalDesktop;
