import { Button } from "@mui/material";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelGrid from "components/nuvel/Grid";
import NuvelSelect from "components/nuvel/Select";
import { EtiquetasSerializer } from "data/interfaces/core/EtiquetasSerializer";
import { ProdutoSerializer } from "data/interfaces/estoque/ProdutoSerializer";
import React from "react";
import { handleChange } from "utils/functions";
import {
  converterEtiquetaParaTemplate,
  generateEtiquetaPDF,
} from "utils/functions/renderizarEtiqueta";
interface TabEtiquetasProps {
  state: ProdutoSerializer;
  setState: React.Dispatch<React.SetStateAction<ProdutoSerializer>>;
}

const TabEtiquetas: React.FC<TabEtiquetasProps> = ({ state }) => {
  const [localstate, setLocalState] = React.useState<{
    formatoSelecionado: string;
    codBarrasSelecionado: string | null;
    templateSelecionado: EtiquetasSerializer | null;
  }>({
    formatoSelecionado: "ETIQUETA SIMPLES",
    codBarrasSelecionado: null,
    templateSelecionado: null,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function imprimirEtiquetas(e: any) {
    e.preventDefault();
    generateEtiquetaPDF(
      {
        cod_barra: String(localstate.codBarrasSelecionado),
        preco_venda: state.preco_venda,
        produto_nome: state.nome,
        quantidade: 1,
      },
      converterEtiquetaParaTemplate(
        localstate.templateSelecionado as EtiquetasSerializer
      ),
      1,
      true
    );
  }

  return (
    <NuvelGrid container spacing={1}>
      <NuvelGrid xs={12} md={5}>
        <NuvelAutocomplete
          label="Template"
          name="templateSelecionado"
          value={localstate.templateSelecionado?.id}
          onChange={(_e, _v, r) => {
            if (r) {
              setLocalState({
                ...localstate,
                templateSelecionado: r as EtiquetasSerializer,
              });
            }
          }}
          reference="etiquetas"
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={4}>
        <NuvelSelect
          label="Código de barras"
          name="codBarrasSelecionado"
          value={localstate.codBarrasSelecionado}
          onChange={(e) => handleChange(e, setLocalState)}
          options={state.codbarras.map((c) => ({
            label: c.cod_barra,
            value: c.cod_barra,
          }))}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={3}>
        <Button
          onClick={imprimirEtiquetas}
          fullWidth
          style={{ height: "100%" }}
          variant="contained"
        >
          {" "}
          Imprimir Etiquetas{" "}
        </Button>
      </NuvelGrid>
    </NuvelGrid>
  );
};

export default TabEtiquetas;
