/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputAdornment, InputBase } from "@mui/material";
import IntlCurrencyInput from "react-intl-currency-input";

interface InputFieldProps {
  money?: boolean;
  perc?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: number;
  decimals?: number;
  style?: React.CSSProperties;
  type?: string;
  inputProps?: any;
  label?: string;
  align?: "left" | "center" | "right";
}

export default function InputField(props: InputFieldProps) {
  const inputProps: any = props.perc
    ? {
        startAdornment: <InputAdornment position="start"> % </InputAdornment>,
      }
    : {
        startAdornment: <InputAdornment position="start"> R$ </InputAdornment>,
      };

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "decimal",
          currency: "BRL",
          minimumFractionDigits: props.decimals || 2,
          maximumFractionDigits: props.decimals || 2,
        },
      },
    },
  };

  if (props.money || props.perc) {
    return (
      <InputBase
        {...props}
        value={Number(props.value)}
        inputComponent={IntlCurrencyInput as React.ComponentType<any>}
        inputProps={{ ...inputProps, currency: "BRL", config: currencyConfig }}
        // @ts-expect-error aaaaaa
        onChange={(e: any, v: any) =>
          props.onChange({
            ...e,
            target: {
              ...e.target,
              name: props.name,
              value: v,
            },
          })
        }
        fullWidth
        id={props.name}
      />
    );
  } else {
    return <InputBase {...props} fullWidth id={props.name} />;
  }
}
