/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AUTOCOMPLETE_MODELS, {
  AutocompleteModel,
  ReferenceModelMap,
  ValidReference,
} from "constants/nuvel/Autocomplete";
import useDebounce from "hooks/useDebounce";
import * as React from "react";
import { NuvelMultipleSelectValue } from "utils/types";

interface OptionType {
  label: string;
  value: number | string;
  object?: any;
}

export interface NuvelAutocompleteEvent {
  target: { name: string; value: NuvelMultipleSelectValue };
}

export interface NuvelMultipleAutocompleteProps<K extends ValidReference> {
  reference: K;
  name: string;
  label: string;
  disabled?: boolean;
  filter?: {
    [key: string]: any;
  };
  clearAfterSelect?: boolean;
  helperText?: string;
  error?: boolean;
  value: NuvelMultipleSelectValue;
  onChange: (
    event: NuvelAutocompleteEvent,
    value: NuvelMultipleSelectValue,
    fullValue: ReferenceModelMap[K][] | undefined,
    reason?: string,
    details?: any
  ) => void;
  onFetchCurrentObject?: (value: ReferenceModelMap[K][]) => void;
}

function NuvelMultipleAutocomplete<K extends ValidReference>({
  name,
  reference,
  label,
  value,
  onChange,
  filter,
  clearAfterSelect = false,
  helperText,
  error,
  onFetchCurrentObject,
  ...props
}: NuvelMultipleAutocompleteProps<K>) {
  const [options, setOptions] = React.useState<OptionType[] | undefined>();
  const [inputValue, setInputValue] = React.useState<string>("");

  const debouncedInputValue = useDebounce(inputValue, 250);

  const referenced_model = React.useMemo(() => {
    return AUTOCOMPLETE_MODELS.find(
      (model) => model.reference === reference
    ) as AutocompleteModel<ReferenceModelMap[K], ReferenceModelMap[K]>;
  }, [reference]);

  if (!referenced_model) {
    throw new Error(
      `Modelo de autocomplete não encontrado para a referência ${reference}`
    );
  }

  const { data, isValidating } = referenced_model.model.useModel({
    search: debouncedInputValue,
    ...(referenced_model.filters || {}),
    ...(filter || {}),
  });

  const [currentObject, setCurrentObject] = React.useState<
    ReferenceModelMap[K][] | null
  >(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (data && referenced_model && !isValidating) {
      const finalOptions: OptionType[] = [];
      finalOptions.push(
        ...data.rows
          .filter((item: any) => item[referenced_model.label_by] !== "")
          .map((item: any) => ({
            label: item[referenced_model.label_by] as string,
            value: item[referenced_model.value_by] as number | string,
            object: item,
          }))
      );
      if (currentObject) {
        currentObject.forEach((item: any) => {
          if (
            !finalOptions.some(
              (option) =>
                String(option.value) === String(item[referenced_model.value_by])
            )
          ) {
            finalOptions.push({
              label: item[referenced_model.label_by] as string,
              value: item[referenced_model.value_by] as number | string,
              object: item,
            });
          }
        });
      }
      setOptions(
        finalOptions.sort((a, b) =>
          a.label.localeCompare(b.label, undefined, {
            sensitivity: "accent",
          })
        )
      );
    }
  }, [data, referenced_model, isValidating, currentObject]);

  const fetchCurrentObject = React.useCallback(
    async (value: (string | number)[]) => {
      setIsLoading(true);
      const objects = await referenced_model.model.get(undefined, {
        [String(referenced_model.value_by) + "__in"]: value.join(","),
      });
      setCurrentObject(objects.rows);
      onFetchCurrentObject?.(objects.rows);
      setIsLoading(false);
    },
    [referenced_model.model, referenced_model.value_by, onFetchCurrentObject]
  );

  React.useEffect(() => {
    if (value && value.length > 0) {
      fetchCurrentObject(value);
    } else {
      setCurrentObject(null);
    }
  }, [fetchCurrentObject, value]);

  const selectedOptions = React.useMemo(() => {
    if (value === null || value === undefined) {
      return [];
    }
    return options?.filter((option) => value.includes(option.value)) || [];
  }, [value, options]);

  const handleInputChange = React.useCallback(
    (_event: React.SyntheticEvent, newInputValue: string) => {
      setInputValue(newInputValue);
    },
    []
  );

  const handleChange = React.useCallback(
    (
      _event: React.SyntheticEvent,
      newValue: OptionType[] | null,
      _reason: string,
      _details?: any
    ) => {
      const value = newValue?.map((option) => option.value) || [];
      onChange(
        {
          target: {
            name,
            value,
          },
        },
        value,
        newValue?.map((option) => option.object),
        _reason,
        _details
      );
      if (clearAfterSelect) {
        setInputValue("");
      }
    },
    [clearAfterSelect, onChange, name]
  );

  return (
    <Autocomplete<OptionType, true, boolean, boolean>
      {...props}
      multiple
      loading={options === undefined || isLoading || isValidating}
      disablePortal
      freeSolo={true}
      id={`combo-box-${name}`}
      options={options || []}
      value={selectedOptions}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={(e, v, r, d) => handleChange(e, v as OptionType[], r, d)}
      fullWidth
      renderInput={(params) => (
        <TextField
          variant="filled"
          {...params}
          label={label}
          helperText={helperText}
          error={error}
        />
      )}
      inputMode="search"
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            color="primary"
            label={option.label}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
}

export default React.memo(
  NuvelMultipleAutocomplete
) as typeof NuvelMultipleAutocomplete;
