import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Dropzone from "components/nuvel/DropZone";
import NuvelModal from "components/nuvel/Modal";
import React, { useCallback, useEffect, useState } from "react";
import { createTerminalDePrecoFormatter } from "utils/functions/terminal_de_preco";
import { GenericTerminalDePrecoFormatterType } from "utils/functions/terminal_de_preco/types";

interface ModalTesteExportacaoProps {
  open: boolean;
  onClose: () => void;
}

const ModalTesteExportacao: React.FC<ModalTesteExportacaoProps> = ({
  open,
  onClose,
}) => {
  const [data, setData] = useState<{
    filename: string;
    base64: string | ArrayBuffer | null;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [list, setList] = useState<GenericTerminalDePrecoFormatterType[]>([]);

  const handleClose = () => {
    setData(null);
    onClose();
    setList([]);
  };

  const parseData = useCallback(() => {
    if (data?.base64) {
      const base64String =
        (data.base64 as string).split("base64,")[1] || data.base64;
      try {
        const binaryString = atob(base64String as string);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const decoder = new TextDecoder("utf-8");
        const text = decoder.decode(bytes);

        const formatter = createTerminalDePrecoFormatter("PRICETAB");
        const lines = text.split("\n");
        const formattedLines = lines.map((line) => formatter.parseLine(line));

        setList(formattedLines);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_e) {
        setError("Erro ao decodificar TXT");
      }
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      parseData();
    }
  }, [data, parseData]);

  return (
    <NuvelModal
      open={open}
      title={
        data && data.filename ? `Arquivo: ${data.filename}` : "Teste Exportação"
      }
      btnSave={handleClose}
      saveOnly
      saveText="Fechar"
      minWidth="xl"
      fullWidth
      noPadding
    >
      {!data && (
        <Dropzone setData={setData} accept={{ "file/text": [".txt"] }} />
      )}
      {error && <Typography color="error">{error}</Typography>}
      {list.length > 0 && (
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Preços</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((item) => (
                <TableRow key={item.code}>
                  <TableCell>{item.code}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.prices.join(", ")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </NuvelModal>
  );
};

export default ModalTesteExportacao;
