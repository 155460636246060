/* eslint-disable @typescript-eslint/no-explicit-any */
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AUTOCOMPLETE_MODELS, {
  AutocompleteModel,
  ReferenceModelMap,
  ValidReference,
} from "constants/nuvel/Autocomplete";
import useDebounce from "hooks/useDebounce";
import * as React from "react";
import { NuvelSelectValue } from "utils/types";

interface OptionType {
  label: string;
  value: number | string;
  object?: any;
}

export interface NuvelAutocompleteEvent {
  target: { name: string; value: NuvelSelectValue };
}

export interface NuvelAutocompleteProps<K extends ValidReference> {
  reference: K;
  name: string;
  label: string;
  disabled?: boolean;
  filter?: {
    [key: string]: any;
  };
  clearAfterSelect?: boolean;
  helperText?: string;
  error?: boolean;
  value: NuvelSelectValue;
  onChange: (
    event: NuvelAutocompleteEvent,
    value: NuvelSelectValue,
    fullValue: ReferenceModelMap[K] | undefined,
    reason?: string,
    details?: any
  ) => void;
  onFetchCurrentObject?: (value: ReferenceModelMap[K]) => void;
  page_size?: number;
}

function NuvelAutocomplete<K extends ValidReference>({
  name,
  reference,
  label,
  value,
  onChange,
  filter,
  clearAfterSelect = false,
  helperText,
  error,
  onFetchCurrentObject,
  page_size = 10,
  ...props
}: NuvelAutocompleteProps<K>) {
  const [options, setOptions] = React.useState<OptionType[] | undefined>();
  const [inputValue, setInputValue] = React.useState<string>("");

  const debouncedInputValue = useDebounce(inputValue, 250);

  const referenced_model = React.useMemo(() => {
    return AUTOCOMPLETE_MODELS.find(
      (model) => model.reference === reference
    ) as AutocompleteModel<ReferenceModelMap[K], ReferenceModelMap[K]>;
  }, [reference]);

  if (!referenced_model) {
    throw new Error(
      `Modelo de autocomplete não encontrado para a referência ${reference}`
    );
  }

  const { data, isValidating } = referenced_model.model.useModel({
    search: debouncedInputValue,
    ...(referenced_model.filters || {}),
    ...(filter || {}),
    page_size: page_size,
  });

  const [currentObject, setCurrentObject] = React.useState<
    ReferenceModelMap[K] | null
  >(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (data && referenced_model && !isValidating) {
      const finalOptions: OptionType[] = [];
      finalOptions.push(
        ...data.rows
          .filter((item: any) => item[referenced_model.label_by] !== "")
          .map((item: any) => ({
            label: item[referenced_model.label_by] as string,
            value: item[referenced_model.value_by] as number | string,
            object: item,
          }))
      );
      if (currentObject) {
        const isCurrentObjectInOptions = finalOptions.some(
          (option) => option.value === currentObject[referenced_model.value_by]
        );

        if (!isCurrentObjectInOptions) {
          finalOptions.push({
            label: currentObject[referenced_model.label_by] as string,
            value: currentObject[referenced_model.value_by] as number | string,
            object: currentObject,
          });
        }
      }

      setOptions(finalOptions);
    }
  }, [data, referenced_model, isValidating, currentObject]);

  const fetchCurrentObject = React.useCallback(
    async (value: string | number) => {
      setIsLoading(true);
      const objects = await referenced_model.model.get(undefined, {
        [String(referenced_model.value_by) + "__in"]: value,
      });
      setCurrentObject(objects.rows?.[0]);
      onFetchCurrentObject?.(objects.rows?.[0]);
      setIsLoading(false);
    },
    [referenced_model.model, referenced_model.value_by, onFetchCurrentObject]
  );

  React.useEffect(() => {
    if (value !== null && value !== undefined && value !== "" && value !== 0) {
      if (currentObject?.[referenced_model.value_by] !== value) {
        fetchCurrentObject(value);
      }
    } else {
      setCurrentObject(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const selectedOption = React.useMemo(() => {
    return options?.find((option) => option.value === value) || null;
  }, [value, options]);

  const handleInputChange = React.useCallback(
    (_event: React.SyntheticEvent, newInputValue: string) => {
      setInputValue(newInputValue);
    },
    []
  );

  const handleChange = React.useCallback(
    (
      _event: React.SyntheticEvent,
      newValue: OptionType | null,
      _reason: string,
      _details?: any
    ) => {
      const value = newValue?.value || null;
      onChange(
        {
          target: {
            name,
            value,
          },
        },
        value,
        newValue?.object,
        _reason,
        _details
      );
      if (clearAfterSelect) {
        setInputValue("");
      }
    },
    [clearAfterSelect, onChange, name]
  );

  return (
    <Autocomplete<OptionType, false, boolean, boolean>
      {...props}
      multiple={false}
      loading={options === undefined || isLoading || isValidating}
      disablePortal
      id={`combo-box-${name}`}
      options={options || []}
      value={selectedOption}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={(e, v, r, d) => handleChange(e, v as OptionType | null, r, d)}
      fullWidth
      renderInput={(params) => (
        <TextField
          variant="filled"
          {...params}
          label={label}
          helperText={helperText}
          error={error}
        />
      )}
      inputMode="search"
    />
  );
}

export default React.memo(NuvelAutocomplete) as typeof NuvelAutocomplete;
