import { RouteInterface } from "utils/router";

import RetAjusteDeEstoqueForm from "../views/estoque/ajuste_de_estoque/form";
import RetAjusteDeEstoqueList from "../views/estoque/ajuste_de_estoque/list";
import RetDepositoForm from "../views/estoque/deposito/form";
import RetDepositoList from "../views/estoque/deposito/list";
import RetEtiquetaEmLoteForm from "../views/estoque/etiqueta_em_lote/form";
import RetExportarBalancaForm from "../views/estoque/exportar_balanca/form";
import RetExportarTerminalDePrecoForm from "../views/estoque/exportar_terminal_de_preco/form";
import RetGradeForm from "../views/estoque/grade/form";
import RetGradeList from "../views/estoque/grade/list";
import RetNotaEntradaForm from "../views/estoque/nota_entrada/form";
import RetPromocaoForm from "../views/estoque/promocao/form";
import RetPromocaoList from "../views/estoque/promocao/list";
import RetTipoInsumoForm from "../views/estoque/tipo_insumo/form";
import RetTipoInsumoList from "../views/estoque/tipo_insumo/list";
import RetUnidadesForm from "../views/estoque/unidades/form";
import RetUnidadesList from "../views/estoque/unidades/list";

const estoqueRoutes: RouteInterface[] = [
  {
    title: "Nota de Entrada",
    path: "/retaguarda/estoque/nota_entrada",
    element: <RetNotaEntradaForm />,
    perm: "ESTOQUE_NOTA_DE_ENTRADA",
  },
  {
    title: "Depósito",
    path: "/retaguarda/estoque/deposito",
    element: <RetDepositoList />,
    form: <RetDepositoForm />,
    perm: "ESTOQUE_DEPOSITO",
  },
  {
    title: "Ajuste de Estoque",
    path: "/retaguarda/estoque/ajuste",
    element: <RetAjusteDeEstoqueList />,
    form: <RetAjusteDeEstoqueForm />,
    perm: "ESTOQUE_AJUSTE_DE_ESTOQUE",
  },
  {
    title: "Grade",
    path: "/retaguarda/estoque/grade",
    element: <RetGradeList />,
    form: <RetGradeForm />,
    perm: "ESTOQUE_GRADE",
  },
  {
    title: "Tipo de Insumo",
    path: "/retaguarda/estoque/tipo_insumo",
    element: <RetTipoInsumoList />,
    form: <RetTipoInsumoForm />,
    perm: "ESTOQUE_TIPO_DE_INSUMO",
  },
  {
    title: "Promoção",
    path: "/retaguarda/estoque/promocao",
    element: <RetPromocaoList />,
    form: <RetPromocaoForm />,
    perm: "ESTOQUE_PROMOCAO",
  },
  {
    title: "Unidades",
    path: "/retaguarda/estoque/unidades",
    element: <RetUnidadesList />,
    form: <RetUnidadesForm />,
    perm: "ESTOQUE_UNIDADES",
  },
  {
    title: "Etiqueta em Lote",
    path: "/retaguarda/estoque/etiqueta_em_lote",
    element: <RetEtiquetaEmLoteForm />,
    perm: "ESTOQUE_ETIQUETA_EM_LOTE",
  },
  {
    title: "Exportar Balança",
    path: "/retaguarda/estoque/exportar_balanca",
    element: <RetExportarBalancaForm />,
    perm: "ESTOQUE_EXPORTAR_BALANCA",
  },
  {
    title: "Exportar Terminal de Preço",
    path: "/retaguarda/estoque/exportar_terminal_de_preco",
    element: <RetExportarTerminalDePrecoForm />,
    perm: "ESTOQUE_EXPORTAR_TERMINAL_DE_PRECO",
  },
];

export default estoqueRoutes;
