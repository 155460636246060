import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import {
  defaultFiltros,
  TransacaoFilters,
} from "constants/retaguarda/financeiro/contas_a_pagar";
import { ContaBancariaModel } from "data/models";
import React, { useState } from "react";

import { ContaBancariaSerializer } from "data/interfaces/financeiro/ContaBancariaSerializer";
import Filtros from "./filtros";
import ListValeFuncionario from "./list";
import ModalCredito from "./modals/credito";
import ModalDebito from "./modals/debito";
import ModalOperacoes from "./modals/operacoes";

const RetValeFuncionarioForm: React.FC = () => {
  const [modal, setModal] = useState<{
    open: boolean;
    id: number;
    conta_id: number | undefined;
  }>({
    open: false,
    id: 0,
    conta_id: undefined,
  });
  const [filters, setFilters] = useState<TransacaoFilters>(defaultFiltros);
  const [page, setPage] = useState(1);
  const { data, isValidating, mutate } = ContaBancariaModel.useModel({
    parceiro_de_negocios__funcionario_ativo: true,
    parceiro_de_negocios__func: true,
    page: page,
  });

  const handleModal = (id: number, conta_id?: number | undefined) => {
    setModal((_v) => ({
      ..._v,
      open: !_v.open,
      conta_id: conta_id,
      id,
    }));
  };

  const handleChangePage = (_event: unknown, value: number) => {
    setPage(value);
  };

  const rows_counts = data?.totalCount || 0;
  const total_pages = Math.ceil(rows_counts / 10);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <PaperWithLoad
        loading={!data || isValidating}
        sx={{
          width: "100%",
          borderRadius: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box p={2}>
          <Filtros state={filters} setState={setFilters} />
        </Box>
        <Divider variant="middle" />
        <ListValeFuncionario
          data={
            data
              ? {
                  page: data.page,
                  rows: data.rows as ContaBancariaSerializer[],
                  totalCount: data.totalCount,
                }
              : undefined
          }
          handleModal={handleModal}
          page={page}
          total_pages={total_pages}
          handleChangePage={handleChangePage}
          modal={modal}
        />
      </PaperWithLoad>
      {modal.id === 1 && (
        <ModalCredito
          id={1}
          modal={modal}
          handleModal={handleModal}
          mutate={mutate}
        />
      )}
      {modal.id === 2 && (
        <ModalDebito
          id={2}
          modal={modal}
          handleModal={handleModal}
          mutate={mutate}
        />
      )}
      {modal.id === 3 && (
        <ModalOperacoes id={3} modal={modal} handleModal={handleModal} />
      )}
    </Box>
  );
};

export default RetValeFuncionarioForm;
