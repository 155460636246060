import { Box } from "@mui/material";
import EtiquetaEditor, {
  EtiquetaProvider,
} from "components/retaguarda/etiqueta";

const RetEtiquetasForm = () => {
  return (
    <Box>
      <EtiquetaProvider>
        <EtiquetaEditor />
      </EtiquetaProvider>
    </Box>
  );
};

export default RetEtiquetasForm;
