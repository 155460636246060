/* eslint-disable @typescript-eslint/no-explicit-any */
import { Delete } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Input, styled } from "@mui/material";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelCheckbox from "components/nuvel/Checkbox";
import { NuvelRawForm } from "components/nuvel/forms";
import NuvelGrid from "components/nuvel/Grid";
import NuvelMultipleAutocomplete from "components/nuvel/MultipleAutocomplete";
import NuvelSelect from "components/nuvel/Select";
import NuvelTextField from "components/nuvel/TextField";
import { CodBarraSerializer } from "data/interfaces/estoque/CodBarraSerializer";
import { CatalogoModel } from "data/models";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { handleChange } from "utils/functions";

interface RetCatalogoFormState {
  nome: string;
  descricao: string;
  taxa_entrega: number;
  permite_retirada_local: boolean;
  is_catalogo_padrao: boolean;
  formas_pagamento: {
    forma: number;
  }[];
  formas_pagamento_temp: number[];
  grupos: {
    nome: string;
    descricao: string;
    departamento: number | null;
    ordem: number;
    produtos: {
      cod_barra: number | null;
      cod_barra_temp?: any;
      preco: number;
    }[];
  }[];
}

const GroupContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const GroupHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  fontWeight: "bold",
  fontSize: "1.4rem",
  marginBottom: theme.spacing(1),
}));

const GroupHeaderInput = styled(Input)(() => ({
  fontSize: "1.4rem",
  fontWeight: "bold",
}));

const GroupItemContainer = styled(Box)(() => ({
  width: "100%",
}));

const GroupAddItem = styled(Button)(() => ({
  border: "1px dashed",
  width: "100%",
  height: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const RenderedGroup = ({
  grupo,
  index,
  setState,
  id,
}: {
  grupo: RetCatalogoFormState["grupos"][number];
  index: number;
  setState: React.Dispatch<React.SetStateAction<RetCatalogoFormState>>;
  id: string | undefined;
}) => {
  const handleChangeGrupo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState: RetCatalogoFormState) => ({
      ...prevState,
      grupos: prevState.grupos.map((g, i) =>
        i === index ? { ...g, [e.target.name]: e.target.value } : g
      ),
    }));
  };

  const handleChangeGrupoItem = (
    e: {
      target: {
        name: string;
        value: string | number | null | CodBarraSerializer;
      };
    },
    prod_index: number
  ) => {
    setState((prevState: RetCatalogoFormState) => ({
      ...prevState,
      grupos: prevState.grupos.map((g, i) =>
        i === index
          ? {
              ...g,
              produtos: prevState.grupos[index].produtos.map((p, j) =>
                j === prod_index ? { ...p, [e.target.name]: e.target.value } : p
              ),
            }
          : g
      ),
    }));
  };

  const handleAddItem = () => {
    setState((prevState: RetCatalogoFormState) => ({
      ...prevState,
      grupos: prevState.grupos.map((g, i) =>
        i === index
          ? {
              ...g,
              produtos: [...g.produtos, { cod_barra: null, preco: 0 }],
            }
          : g
      ),
    }));
  };

  const handleRemoveItem = (prod_index: number) => {
    setState((prevState: RetCatalogoFormState) => ({
      ...prevState,
      grupos: prevState.grupos.map((g, i) =>
        i === index
          ? {
              ...g,
              produtos: g.produtos.filter((_, j) => j !== prod_index),
            }
          : g
      ),
    }));
  };

  const handleRemoveGroup = () => {
    setState((prevState: RetCatalogoFormState) => ({
      ...prevState,
      grupos: prevState.grupos.filter((_, i) => i !== index),
    }));
  };

  return (
    <GroupContainer>
      <Box p={2} width="100%">
        <GroupHeader>
          <GroupHeaderInput
            name="nome"
            value={grupo.nome}
            onChange={handleChangeGrupo}
          />
          <IconButton onClick={handleRemoveGroup}>
            <Delete />
          </IconButton>
        </GroupHeader>
        <Divider />
      </Box>
      {grupo.produtos?.map((produto, index) => (
        <GroupItemContainer key={index}>
          <NuvelGrid container spacing={1} sx={{ p: 2, pt: 0 }}>
            <NuvelGrid size={{ xs: 4 }}>
              <NuvelAutocomplete
                label="Código de Barras"
                name="cod_barra"
                value={produto.cod_barra as number}
                onChange={(_, v, f) => {
                  handleChangeGrupoItem(
                    {
                      target: { name: "cod_barra", value: v as string },
                    },
                    index
                  );
                  handleChangeGrupoItem(
                    {
                      target: { name: "cod_barra_temp", value: f || null },
                    },
                    index
                  );
                }}
                reference="cod_barra"
                filter={{
                  has_preco: true,
                }}
                onFetchCurrentObject={(obj) => {
                  if (id && id !== "novo") {
                    handleChangeGrupoItem(
                      {
                        target: {
                          name: "cod_barra_temp",
                          value: obj,
                        },
                      },
                      index
                    );
                  }
                }}
              />
            </NuvelGrid>
            <NuvelGrid size={{ xs: 7 }}>
              <NuvelSelect
                label="Preço"
                name="preco"
                value={produto.preco}
                onChange={(e) => handleChangeGrupoItem(e, index)}
                options={produto.cod_barra_temp?.precos.map((p: any) => ({
                  label: `${p.preco_nome} - ${p.preco_venda.toBRL()}`,
                  value: p.id,
                }))}
                disabled={!produto.cod_barra_temp}
              />
            </NuvelGrid>
            <NuvelGrid
              size={{ xs: 1 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={() => handleRemoveItem(index)}>
                <Delete />
              </IconButton>
            </NuvelGrid>
          </NuvelGrid>
        </GroupItemContainer>
      ))}
      <Divider sx={{ width: "100%" }} />
      <Box p={2} width="100%">
        <GroupAddItem onClick={handleAddItem}>+ Adicionar Item</GroupAddItem>
      </Box>
    </GroupContainer>
  );
};

const RetCatalogoForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [state, setState] = useState<RetCatalogoFormState>({
    nome: "",
    descricao: "",
    taxa_entrega: 0,
    permite_retirada_local: false,
    is_catalogo_padrao: false,
    formas_pagamento: [],
    formas_pagamento_temp: [],
    grupos: [
      {
        nome: "Geral",
        descricao: "",
        departamento: null,
        ordem: 1,
        produtos: [
          {
            cod_barra: null,
            preco: 0,
          },
        ],
      },
    ],
  });

  const handleAddGroup = () => {
    setState((prevState: RetCatalogoFormState) => ({
      ...prevState,
      grupos: [
        ...prevState.grupos,
        {
          nome: "Novo Grupo",
          descricao: "",
          departamento: null,
          ordem: prevState.grupos.length + 1,
          produtos: [],
        },
      ],
    }));
  };

  const handleSubmit = (stopLoading: () => void) => {
    CatalogoModel.save(state).then(() => {
      stopLoading();
      navigate(-1);
    });
  };

  React.useEffect(() => {
    if (id && id !== "novo") {
      CatalogoModel.get(Number(id)).then((catalogo) => {
        setState({
          ...catalogo,
          formas_pagamento_temp: catalogo.formas_pagamento.map(
            (forma: any) => forma.forma
          ),
        });
      });
    }
  }, [id]);

  console.log(state);

  return (
    <NuvelRawForm onSubmit={handleSubmit}>
      <NuvelGrid container spacing={1}>
        <NuvelGrid size={{ xs: 4 }}>
          <NuvelTextField
            label="Nome"
            name="nome"
            value={state.nome}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 8 }}>
          <NuvelTextField
            label="Descrição"
            name="descricao"
            value={state.descricao}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 2 }}>
          <NuvelTextField
            label="Taxa de entrega"
            name="taxa_entrega"
            value={state.taxa_entrega}
            onChange={(e) => handleChange(e, setState)}
            money
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 5 }}>
          <NuvelMultipleAutocomplete
            label="Formas de pagamento"
            name="formas_pagamento"
            value={state.formas_pagamento_temp}
            onChange={(_, v) =>
              setState({
                ...state,
                formas_pagamento_temp: v as number[],
                formas_pagamento: (v as number[]).map((v) => ({ forma: v })),
              })
            }
            reference="forma_pagamento"
          />
        </NuvelGrid>
        <NuvelGrid
          size={{ xs: 5 }}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <NuvelCheckbox
            label="Permite retirada local"
            name="permite_retirada_local"
            value={state.permite_retirada_local}
            onChange={(e) => handleChange(e, setState)}
          />
          <NuvelCheckbox
            label="Catálogo padrão"
            name="is_catalogo_padrao"
            value={state.is_catalogo_padrao}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        {state.grupos?.map((grupo, index) => (
          <NuvelGrid key={index} size={{ xs: 12 }}>
            <RenderedGroup
              setState={setState}
              index={index}
              grupo={grupo}
              id={id}
            />
          </NuvelGrid>
        ))}
        <NuvelGrid size={{ xs: 12 }}>
          <Button
            variant="contained"
            sx={{ width: "100%", color: "white" }}
            onClick={handleAddGroup}
          >
            + Adicionar Grupo
          </Button>
        </NuvelGrid>
      </NuvelGrid>
    </NuvelRawForm>
  );
};

export default RetCatalogoForm;
