/* eslint-disable react-native/no-color-literals */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { LojaSerializer } from "data/interfaces/core/LojaSerializer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 10,
  },
  column: {
    flexDirection: "column",
    flex: 1,
    margin: 5,
  },
  header: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
    marginBottom: 5,
  },
  label: {
    width: "50%",
    fontSize: 10,
  },
  value: {
    width: "50%",
    fontSize: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#E4E4E4",
    borderBottomStyle: "solid",
    alignItems: "center",
    height: 24,
  },
  tableCell: {
    fontSize: 10,
    flex: 1,
  },
  infoRow: {
    flexDirection: "row",
    marginBottom: 2,
  },
  storeHeader: {
    marginBottom: 10,
    padding: 15,
  },
  storeInfo: {
    fontSize: 10,
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#666", // Você pode ajustar a cor conforme necessário
    borderBottomStyle: "solid",
  },
});

const formatAddress = (loja: LojaSerializer) => {
  let address = "";
  if (loja.endereco) address += loja.endereco;
  if (loja.numero) address += `, ${loja.numero}`;
  if (loja.complemento) address += ` - ${loja.complemento}`;
  if (loja.bairro) address += `, ${loja.bairro}`;
  if (loja.cidade) address += ` - ${loja.cidade}`;
  if (loja.uf) address += `, ${loja.uf}`;
  if (loja.cep) address += ` - CEP: ${loja.cep}`;
  return address || "Endereço não informado";
};

interface CaixafechamentoCegoProps {
  caixa: any;
  fechamento_cego: any;
  validateField: (field1: any, field2: any) => boolean;
  validatePagamentoIgual: (pagamento1: any, pagamento2: any) => boolean;
  sortByName: (items: any[]) => any[];
  calculateSangriaSuprimento: (operacoes: any[], tipo: number) => number;
  loja: LojaSerializer;
}

const CaixafechamentoCego = ({
  caixa,
  fechamento_cego,
  validateField,
  validatePagamentoIgual,
  sortByName,
  calculateSangriaSuprimento,
  loja,
}: CaixafechamentoCegoProps) => {
  return (
    <Document>
      <Page>
        <View style={styles.storeHeader}>
          <Text style={styles.header}>
            {loja.razao_social || "Não informado"}
          </Text>
          <Text style={styles.storeInfo}>
            CNPJ: {loja.cnpj || "Não informado"}
          </Text>
          <Text style={styles.storeInfo}>Endereço: {formatAddress(loja)}</Text>
          <Text style={styles.storeInfo}>
            Telefone: {loja.telefone || "Não informado"}
          </Text>
          <Text style={styles.storeInfo}>
            Licença: {loja.licenca ? `#${loja.licenca}` : "Não informado"}
          </Text>
          <Text style={styles.storeInfo}>
            Emite NF-e: {loja.emite_nfe ? "Sim" : "Não"}
          </Text>
        </View>
        <View style={styles.divider}></View>
        <View style={styles.container}>
          {/* Início do caixa */}
          <View style={styles.column}>
            <Text style={styles.header}>Fechamento do Caixa</Text>
            <View
              style={{
                ...styles.row,
                color: validateField(
                  caixa?.troco_inicial,
                  fechamento_cego?.troco_inicial
                )
                  ? "red"
                  : "black",
              }}
            >
              <Text style={styles.label}>Troco Inicial:</Text>
              <Text style={styles.value}>{caixa?.troco_inicial.toBRL()}</Text>
            </View>
            <View
              style={{
                ...styles.row,
                color: validateField(
                  caixa?.troco_final,
                  fechamento_cego?.troco_final
                )
                  ? "red"
                  : "black",
              }}
            >
              <Text style={styles.label}>Troco Final:</Text>
              <Text style={styles.value}>{caixa?.troco_final.toBRL()}</Text>
            </View>
            <View
              style={{
                ...styles.row,
                color: validateField(
                  fechamento_cego?.sangria,
                  calculateSangriaSuprimento(caixa?.operacoes, 1)
                )
                  ? "red"
                  : "black",
              }}
            >
              <Text style={styles.label}>Sangria:</Text>
              <Text style={styles.value}>
                {calculateSangriaSuprimento(caixa?.operacoes, 1).toBRL()}
              </Text>
            </View>
            <View
              style={{
                ...styles.row,
                color: validateField(
                  fechamento_cego?.suprimento,
                  calculateSangriaSuprimento(caixa?.operacoes, 2)
                )
                  ? "red"
                  : "black",
              }}
            >
              <Text style={styles.label}>Suprimento:</Text>
              <Text style={styles.value}>
                {calculateSangriaSuprimento(caixa?.operacoes, 2).toBRL()}
              </Text>
            </View>
            <Text style={styles.header}>Pagamentos</Text>
            {sortByName(caixa?.pagamentos).map((item, index) => {
              return (
                <View
                  key={index}
                  style={{
                    ...styles.row,
                    color: validatePagamentoIgual(
                      item,
                      fechamento_cego?.fechamentos_cegos_formas
                    )
                      ? "red"
                      : "black",
                  }}
                >
                  <Text style={styles.label}>{item.forma_nome}:</Text>
                  <Text style={styles.value}>
                    {String(item.forma_nome).toLowerCase() === "dinheiro"
                      ? (
                          parseFloat(item.total) - parseFloat(item.troco)
                        ).toBRL()
                      : (
                          parseFloat(item.total) - parseFloat(item.troco)
                        ).toBRL()}
                  </Text>
                  {/* {item.forma_nome === "Dinheiro" ? (
                    <Text style={styles.value}>{item.troco.toBRL()}</Text>
                  ) : null} */}
                </View>
              );
            })}
            <View
              style={{
                ...styles.row,
                color: validateField(
                  caixa?.pagamentos.reduce(
                    (acc: number, item: { total: number; troco: number }) =>
                      acc + (item.total - item.troco),
                    0
                  ),
                  fechamento_cego?.fechamentos_cegos_formas.reduce(
                    (acc: number, item: { total: number; troco: number }) =>
                      acc + (item.total - item.troco),
                    0
                  )
                )
                  ? "red"
                  : "black",
              }}
            >
              <Text style={styles.label}>Total:</Text>
              <Text style={styles.value}>
                {(
                  caixa?.pagamentos.reduce(
                    (acc: number, item: { total: number; troco: number }) =>
                      acc + (item.total - item.troco),
                    0
                  ) -
                  calculateSangriaSuprimento(caixa?.operacoes, 1) +
                  calculateSangriaSuprimento(caixa?.operacoes, 2)
                ).toBRL()}
              </Text>
              <Text style={styles.value}></Text>
            </View>
          </View>
          {/* Final do caixa */}
          {/* Início do fechamento cego */}
          <View style={styles.column}>
            <Text style={styles.header}>Fechamento Cego</Text>
            <View
              style={{
                ...styles.row,
                color: validateField(
                  caixa?.troco_inicial,
                  fechamento_cego?.troco_inicial
                )
                  ? "red"
                  : "black",
              }}
            >
              <Text style={styles.label}>Troco Inicial:</Text>
              <Text style={styles.value}>
                {fechamento_cego?.troco_inicial.toBRL()}
              </Text>
            </View>
            <View
              style={{
                ...styles.row,
                color: validateField(
                  caixa?.troco_final,
                  fechamento_cego?.troco_final
                )
                  ? "red"
                  : "black",
              }}
            >
              <Text style={styles.label}>Troco Final:</Text>
              <Text style={styles.value}>
                {fechamento_cego?.troco_final.toBRL()}
              </Text>
            </View>
            <View
              style={{
                ...styles.row,
                color: validateField(
                  calculateSangriaSuprimento(caixa?.operacoes, 1),
                  fechamento_cego?.sangria
                )
                  ? "red"
                  : "black",
              }}
            >
              <Text style={styles.label}>Sangria:</Text>
              <Text style={styles.value}>
                {fechamento_cego?.sangria.toBRL()}
              </Text>
            </View>
            <View
              style={{
                ...styles.row,
                color: validateField(
                  calculateSangriaSuprimento(caixa?.operacoes, 2),
                  fechamento_cego?.suprimento
                )
                  ? "red"
                  : "black",
              }}
            >
              <Text style={styles.label}>Suprimento:</Text>
              <Text style={styles.value}>
                {fechamento_cego?.suprimento.toBRL()}
              </Text>
            </View>
            <Text style={styles.header}>Pagamentos</Text>
            {sortByName(fechamento_cego?.fechamentos_cegos_formas).map(
              (item, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      ...styles.row,
                      color: validatePagamentoIgual(item, caixa?.pagamentos)
                        ? "red"
                        : "black",
                    }}
                  >
                    <Text style={styles.label}>{item.forma_nome}:</Text>
                    <Text style={styles.value}>{item.total.toBRL()}</Text>
                    {/* {item.finalizadora === 1 ? (
                      <Text style={styles.value}>{item.troco.toBRL()}</Text>
                    ) : null} */}
                  </View>
                );
              }
            )}
            <View
              style={{
                ...styles.row,
                color: validateField(
                  caixa?.pagamentos.reduce(
                    (acc: number, item: { total: number; troco: number }) =>
                      acc + (item.total - item.troco),
                    0
                  ),
                  fechamento_cego?.fechamentos_cegos_formas.reduce(
                    (acc: number, item: { total: number; troco: number }) =>
                      acc + (item.total - item.troco),
                    0
                  )
                )
                  ? "red"
                  : "black",
              }}
            >
              <Text style={styles.label}>Total:</Text>
              <Text style={styles.value}>
                {fechamento_cego?.fechamentos_cegos_formas
                  .reduce(
                    (acc: number, item: { total: number; troco: number }) =>
                      acc + (item.total - item.troco),
                    0
                  )
                  .toBRL()}
              </Text>
              <Text style={styles.value}></Text>
            </View>
          </View>
          {/* Final do fechamento cego */}
        </View>
      </Page>
    </Document>
  );
};

export default CaixafechamentoCego;
