/* eslint-disable @typescript-eslint/no-explicit-any */
import NuvelCheckbox from "components/nuvel/Checkbox";
import { NuvelDefaultForm } from "components/nuvel/forms";
import NuvelGrid from "components/nuvel/Grid";
import NuvelMultipleAutocomplete from "components/nuvel/MultipleAutocomplete";
import NuvelSelect from "components/nuvel/Select";
import NuvelTextField from "components/nuvel/TextField";
import AccordionContato from "components/retaguarda/accordions/contato";
import AccordionEndereco from "components/retaguarda/accordions/endereco";
import { LojaModel, TagModel } from "data/models";
import React from "react";
import { handleChange } from "utils/functions";

function RetLojaForm() {
  const [state, setState] = React.useState<any>({
    id: null,
    cnpj: "",
    ie: "",
    im: "",
    razao_social: "",
    nome_fantasia: "",
    //endereço
    cep: "",
    endereco: "",
    numero: "",
    complemento: "",
    uf: "",
    bairro: "",
    cidade: "",
    pais: "",
    cnae: "",
    crt: "",
    cod_ibge: "",
    //contato
    email: "",
    telefone: "",
    //detalhes => menu direito
    referencia: "",
    num_id: "",
    nfe_serie: "",
    nfe_sequencia: "",
    tags: [],
    homologacao_nfe: false,
    aliq_pis: 0,
    aliq_cofins: 0,
  });
  const { data: listTag } = TagModel.useModel();

  return (
    <NuvelDefaultForm setState={setState} state={state} model={LojaModel}>
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={12} md={8}>
          <NuvelGrid container spacing={1}>
            <NuvelGrid xs={12} md={4}>
              <NuvelTextField
                label="CNPJ"
                mask="00.000.000/0000-00"
                normalize={(value) =>
                  value
                    .replaceAll(".", "")
                    .replaceAll("/", "")
                    .replaceAll("-", "")
                }
                onChange={(e) => handleChange(e, setState)}
                name="cnpj"
                value={state.cnpj}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={4}>
              <NuvelTextField
                label="IE"
                onChange={(e) => handleChange(e, setState)}
                name="ie"
                normalize={(str) => str.replace(/[^\d]/g, "")}
                value={state.ie}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={4}>
              <NuvelTextField
                label="IM"
                onChange={(e) => handleChange(e, setState)}
                name="im"
                value={state.im}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={12}>
              <NuvelTextField
                label="Razão Social"
                onChange={(e) => handleChange(e, setState)}
                name="razao_social"
                value={state.razao_social}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={12}>
              <NuvelTextField
                label="Nome Fantasia"
                onChange={(e) => handleChange(e, setState)}
                name="nome_fantasia"
                value={state.nome_fantasia}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={9}>
              <NuvelSelect
                label="CRT"
                onChange={(e) => handleChange(e, setState)}
                name="crt"
                value={state.crt}
                options={[
                  { label: "Simples Nacional", value: 1 },
                  { label: "Lucro Presumido", value: 2 },
                  { label: "Regime Nacional", value: 3 },
                ]}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={3}>
              <NuvelTextField
                label="CNAE"
                onChange={(e) => handleChange(e, setState)}
                name="cnae"
                value={state.cnae}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={12}>
              <AccordionEndereco state={state} setState={setState} />
              <AccordionContato
                contato={{
                  nome: "",
                  email: state.email,
                  telefone: state.telefone,
                }}
                setContato={(contato) =>
                  setState({
                    ...state,
                    telefone: contato.telefone,
                    email: contato.email,
                  })
                }
              />
            </NuvelGrid>
          </NuvelGrid>
        </NuvelGrid>
        <NuvelGrid
          xs={12}
          md={4}
          sx={(theme) => ({
            borderLeft: "1px solid #E0E0E0",
            [theme.breakpoints.down("xs")]: {
              borderLeft: "0px solid #E0E0E0",
            },
            padding: theme.spacing(1),
          })}
        >
          <NuvelGrid container spacing={1}>
            <NuvelGrid xs={12} md={12}>
              <NuvelTextField
                label="Referência Nota"
                onChange={(e) => handleChange(e, setState)}
                name="referencia"
                value={state.referencia}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={12}>
              <NuvelTextField
                label="Número Identificador"
                onChange={(e) => handleChange(e, setState)}
                name="num_id"
                type="number"
                value={state.num_id}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={12}>
              <NuvelTextField
                label="Número Série NF-e"
                onChange={(e) => handleChange(e, setState)}
                name="nfe_serie"
                type="number"
                value={state.nfe_serie}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={12}>
              <NuvelTextField
                label="Sequência NF-e"
                type="number"
                onChange={(e) => handleChange(e, setState)}
                name="nfe_sequencia"
                value={state.nfe_sequencia}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={12}>
              <NuvelTextField
                label="Alíquota PIS"
                onChange={(e) => handleChange(e, setState)}
                name="aliq_pis"
                type="number"
                value={state.aliq_pis}
                perc
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={12}>
              <NuvelTextField
                label="Alíquota COFINS"
                type="number"
                onChange={(e) => handleChange(e, setState)}
                name="aliq_cofins"
                value={state.aliq_cofins}
                perc
              />
            </NuvelGrid>

            <NuvelGrid xs={12} md={12}>
              {listTag && listTag?.rows?.length > 0 ? (
                <NuvelMultipleAutocomplete
                  label="Tags"
                  name="tags"
                  value={state.tags}
                  onChange={(_e, v) =>
                    setState({ ...state, tags: v as number[] })
                  }
                  reference="tags"
                />
              ) : (
                ""
              )}
            </NuvelGrid>
          </NuvelGrid>
        </NuvelGrid>
        <NuvelGrid xs={12} md={12}>
          <NuvelCheckbox
            label="Homologação NF-e"
            name="homologacao_nfe"
            checked={state.homologacao_nfe}
            value={state.homologacao_nfe}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelDefaultForm>
  );
}

export default RetLojaForm;
