import DashVendas from "data/controller/dashVendas";

function DashboardDataLayer({ children, data, qtdMaisVendidos }) {
  const defaultParams = {
    ...data,
    ...data.periodoParams,
  };

  const { data: totalPeriodo } = DashVendas.useTotalPeriodo(defaultParams);
  const { data: histogram_v_total } =
    DashVendas.useHistogramVTotal(defaultParams);
  const { data: histogram_horario_comparativo_v_total } =
    DashVendas.useHistogramHorarioComparativoVTotal(defaultParams);
  const { data: totalFormaPagamento } =
    DashVendas.useTotalFormaPagamento(defaultParams);
  const { data: totalEstados } = DashVendas.useTotalEstados(defaultParams);
  const { data: produtosMaisVendidos } = DashVendas.useProdutosMaisVendidos({
    ...defaultParams,
    qt: qtdMaisVendidos,
  });
  const { data: histograma_presente_passado } =
    DashVendas.useHistogramaPresentePassadoComparativo({
      ...defaultParams,
    });

  return (
    <>
      {children &&
        children({
          totalPeriodo,
          histogram_v_total,
          histogram_horario_comparativo_v_total,
          totalFormaPagamento,
          totalEstados,
          produtosMaisVendidos,
          histograma_presente_passado,
        })}
    </>
  );
}

export default DashboardDataLayer;
