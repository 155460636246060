import { ToledoFormatter } from "./formatter";
import { BalancaFormatter } from "./types";

export const createBalancaFormatter = (type: "TOLEDO"): BalancaFormatter => {
  switch (type) {
    case "TOLEDO":
      return new ToledoFormatter();
    default:
      throw new Error(`Formato de balança não suportado: ${type}`);
  }
};
