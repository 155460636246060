import { Paper, PaperProps } from "@mui/material";
import React, { ReactNode } from "react";

interface NuvelPaperProps extends PaperProps {
  children: ReactNode;
}

const NuvelPaper: React.FC<NuvelPaperProps> = ({ children, ...rest }) => {
  return (
    <Paper {...rest} sx={{ position: "relative", borderRadius: 2, ...rest.sx }}>
      {children}
    </Paper>
  );
};

export default NuvelPaper;
