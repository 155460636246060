/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from "@mui/material";
import NuvelAccordion from "components/nuvel/Accordion";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelCheckbox from "components/nuvel/Checkbox";
import NuvelProductCodBarraComponent from "components/nuvel/codbarra/NuvelProductCodBarraComponent";
import NuvelGrid from "components/nuvel/Grid";
import NuvelMultipleAutocomplete from "components/nuvel/MultipleAutocomplete";
import NuvelSelect from "components/nuvel/Select";
import NuvelTextField from "components/nuvel/TextField";
import AuthController from "data/controller/auth";
import { ProdutoSerializer } from "data/interfaces/estoque/ProdutoSerializer";
import { DepartamentoModel, TagModel, TipoInsumoModel } from "data/models";
import React from "react";
import { handleChange } from "utils/functions";
import { showConfirm } from "utils/functions/dialog";

interface TabPrincipalProps {
  state: ProdutoSerializer;
  setState: React.Dispatch<React.SetStateAction<ProdutoSerializer>>;
}

const TabPrincipal: React.FC<TabPrincipalProps> = ({ state, setState }) => {
  const { data: franquia_tipo } = AuthController.useCurrentFranquia();

  React.useEffect(() => {
    if (state.departamento && !state.id) {
      showConfirm(
        "Deseja herdar os tributos do grupo?",
        "Tributação de Produtos",
        handleChangeDep
      );
    }
  }, [state.departamento]);

  const handleChangeDep = async () => {
    const depto = await DepartamentoModel.get(state.departamento as number);
    setState((_v) => ({
      ..._v,
      aliquota: depto.aliquota,
      cest: depto.cest,
      cfop: depto.cfop,
      ncm: depto.ncm,
    }));
  };
  const { data: listTag } = TagModel.useModel();
  const { data: listTipoInsumo } = TipoInsumoModel.useModel();

  function flagsTypeList() {
    if (String(franquia_tipo?.nome).toLowerCase() === "cosechas") {
      return [
        "Permite Base",
        "Permite Complemento",
        "Permite Troca",
        "Permite Troca 2",
        "Produto Adicional",
        "Permite Adoçar",
        "Permite Com Sem Mel",
        "Permite Milk Shake",
        "Permite Trio",
        "Permite Fruta Base",
        "É Extra de Fruta Adicional",
      ];
    }
    return [
      "Flag 1",
      "Flag 2",
      "Flag 3",
      "Flag 4",
      "Flag 5",
      "Flag 6",
      "Flag 7",
      "Flag 8",
      "Flag 9",
      "Flag 10",
      "Flag 11",
    ];
  }

  return (
    <NuvelGrid container spacing={1}>
      <NuvelGrid xs={12} md={4}>
        <NuvelTextField
          label="Código"
          type="number"
          onChange={(e) => handleChange(e, setState)}
          name="codigo"
          value={state.codigo}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={8}>
        <NuvelTextField
          label="Nome"
          onChange={(e) => handleChange(e, setState)}
          name="nome"
          value={state.nome}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={6}>
        <NuvelAutocomplete
          label="Departamento / Grupo / Subgrupo"
          name="departamento"
          value={Number(state.departamento)}
          onChange={(e: any) => handleChange(e, setState)}
          reference="departamento"
          page_size={25}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={6}>
        <NuvelMultipleAutocomplete
          label="Grade"
          name="grades"
          value={state.grades.map((grade) => grade.id)}
          onChange={(_e, _, fullValue) => {
            if (fullValue) {
              setState((_v) => ({ ..._v, grades: fullValue }));
            }
          }}
          reference="grades"
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={12}>
        <NuvelProductCodBarraComponent
          codbarras={state.codbarras}
          grades={state.grades}
          onChangeCodBarra={(codbarras) => {
            setState((_v) => ({ ..._v, codbarras }));
          }}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={6}>
        <NuvelSelect
          label="Classificação"
          value={state.classificacao}
          name="classificacao"
          onChange={(e) => handleChange(e, setState)}
          options={[
            { label: "00 - Mercadoria para Revenda", value: 0 },
            { label: "01 - Materia-Prima", value: 1 },
            { label: "02 - Embalagem", value: 2 },
            { label: "03 - Produto em Processo", value: 3 },
            { label: "04 - Produto Acabado", value: 4 },
            { label: "05 - Subproduto", value: 5 },
            { label: "06 - Produto Intermediario", value: 6 },
            { label: "07 - Material de Uso e Consumo", value: 7 },
            { label: "08 - Ativo Imobilizado", value: 8 },
            { label: "09 - Combustível", value: 9 },
            { label: "10 - Outros Insumos", value: 10 },
            { label: "99 - Outros", value: 11 },
          ]}
        />
      </NuvelGrid>
      {(state.classificacao === 10 && listTipoInsumo?.rows.length) ||
      [].length > 0 ? (
        <NuvelGrid xs={12} md={3}>
          <NuvelAutocomplete
            label="Tipo de Insumos"
            value={state.tipo_insumo as number}
            onChange={(e) => handleChange(e, setState)}
            name="tipo_insumo"
            reference="insumo"
          />
        </NuvelGrid>
      ) : null}
      {!state.id ? (
        <NuvelGrid xs={12} md={3}>
          <NuvelTextField
            label="Estoque Inicial"
            decimal
            onChange={(e) => handleChange(e, setState)}
            name="estoque_inicial"
            value={state.estoque_inicial}
          />
        </NuvelGrid>
      ) : (
        <NuvelGrid xs={12} md={3}>
          <NuvelTextField
            label="Prioridade"
            onChange={(e) => handleChange(e, setState)}
            name="prioridade"
            value={state.prioridade}
            type="number"
          />
        </NuvelGrid>
      )}
      <>
        <NuvelGrid xs={12} md={4}>
          <NuvelTextField
            label="Preço Custo"
            money
            onChange={(e) => handleChange(e, setState)}
            name="preco_custo"
            value={state.preco_custo}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={4}>
          <NuvelTextField
            label="Preço Venda"
            money
            onChange={(e) => handleChange(e, setState)}
            name="preco_venda"
            value={state.preco_venda}
          />
        </NuvelGrid>
        <NuvelGrid
          xs={12}
          md={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5">
            {(() => {
              const calc =
                (Number(state.preco_venda) * 100) / Number(state.preco_custo) -
                100;
              if (Number.isNaN(calc)) return "Lucro: ...";
              if (calc > 0) {
                return `Lucro: + ${calc.toDecimal(2)}%`;
              } else {
                return `Lucro ${calc.toDecimal(2)}%`;
              }
            })()}
          </Typography>
        </NuvelGrid>
      </>
      {listTag?.rows?.length || [].length > 0 ? (
        <NuvelGrid xs={12} md={4}>
          <NuvelMultipleAutocomplete
            label="Tags"
            name="tags"
            value={state.tags}
            onChange={(_e, v) => setState({ ...state, tags: v as number[] })}
            reference="tags"
          />
        </NuvelGrid>
      ) : null}
      {!state.id && (
        <NuvelGrid xs={12} md={3}>
          <NuvelTextField
            label="Prioridade"
            onChange={(e) => handleChange(e, setState)}
            name="prioridade"
            value={state.prioridade}
            type="number"
          />
        </NuvelGrid>
      )}
      <NuvelGrid
        xs={12}
        md={3}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NuvelCheckbox
          checked={state.usa_balanca}
          name="usa_balanca"
          onChange={(e) => handleChange(e, setState)}
          label="Usa Balança"
        />
      </NuvelGrid>
      <NuvelGrid
        xs={12}
        md={3}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NuvelCheckbox
          checked={state.proibe_pesagem_manual}
          name="proibe_pesagem_manual"
          onChange={(e) => handleChange(e, setState)}
          label="Proibir pesagem manual"
        />
      </NuvelGrid>
      <NuvelGrid
        xs={12}
        md={3}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NuvelCheckbox
          checked={state.produto_composto}
          name="produto_composto"
          onChange={(e) => handleChange(e, setState)}
          label="Produto Composto"
        />
      </NuvelGrid>
      <NuvelGrid
        xs={12}
        md={3}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NuvelCheckbox
          checked={state.registrar_individualmente}
          name="registrar_individualmente"
          onChange={(e) => handleChange(e, setState)}
          label="Registrar Individualmente"
        />
      </NuvelGrid>
      <NuvelGrid xs={12}>
        <NuvelAccordion title="Flags (Opcional)">
          <NuvelGrid container spacing={2}>
            {flagsTypeList().map((flag, index) => {
              const flag_name = `flag_${index + 1}`;
              return (
                <NuvelGrid xs={12} md={3} key={index}>
                  <NuvelCheckbox
                    checked={state[flag_name as keyof ProdutoSerializer]}
                    name={flag_name}
                    onChange={(e) => handleChange(e, setState)}
                    label={flag}
                  />
                </NuvelGrid>
              );
            })}
          </NuvelGrid>
        </NuvelAccordion>
      </NuvelGrid>
    </NuvelGrid>
  );
};

export default TabPrincipal;
