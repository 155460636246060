import React, { createContext, ReactNode, useContext, useReducer } from "react";
import { ElementoBase, Produto, Template } from "./types";

// Limite máximo de ações no histórico
const MAX_HISTORICO = 50;

interface EtiquetaState {
  template: Template;
  elementoSelecionado: ElementoBase | null;
  produtoExemplo: Produto | undefined;
  mostrarGrid: boolean;
  gridSize: number;
  tamanhoSelecionado: string;
  historico: {
    passado: Template[];
    presente: Template;
    futuro: Template[];
  };
}

type EtiquetaAction =
  | { type: "SET_TEMPLATE"; payload: Template }
  | { type: "SET_ELEMENTO_SELECIONADO"; payload: ElementoBase | null }
  | { type: "SET_PRODUTO_EXEMPLO"; payload: Produto | undefined }
  | { type: "SET_MOSTRAR_GRID"; payload: boolean }
  | { type: "SET_GRID_SIZE"; payload: number }
  | { type: "SET_TAMANHO_SELECIONADO"; payload: string }
  | { type: "ADICIONAR_ELEMENTO"; payload: ElementoBase }
  | { type: "REMOVER_ELEMENTO"; payload: string }
  | { type: "ATUALIZAR_ELEMENTO"; payload: ElementoBase }
  | {
      type: "ATUALIZAR_DIMENSOES";
      payload: { largura: number; altura: number };
    }
  | { type: "ATUALIZAR_DPMM"; payload: number }
  | {
      type: "ATUALIZAR_COLUNAS";
      payload: { colunas: number; espacamento: number };
    }
  | { type: "DESFAZER" }
  | { type: "REFAZER" };

const initialState: EtiquetaState = {
  template: {
    id: "1",
    nome: "Novo Template",
    largura: 100,
    altura: 50,
    dpmm: 8,
    elementos: [],
    colunas: 1,
    espacamentoColunas: 2,
  },
  elementoSelecionado: null,
  produtoExemplo: undefined,
  mostrarGrid: true,
  gridSize: 10,
  tamanhoSelecionado: "personalizado",
  historico: {
    passado: [],
    presente: {
      id: "1",
      nome: "Novo Template",
      largura: 100,
      altura: 50,
      dpmm: 8,
      elementos: [],
      colunas: 1,
      espacamentoColunas: 2,
    },
    futuro: [],
  },
};

const EtiquetaContext = createContext<
  | {
      state: EtiquetaState;
      dispatch: React.Dispatch<EtiquetaAction>;
      podeDesfazer: boolean;
      podeRefazer: boolean;
    }
  | undefined
>(undefined);

// Função para clonar um template
const clonarTemplate = (template: Template): Template => ({
  ...template,
  elementos: template.elementos.map((elem) => ({ ...elem })),
});

function etiquetaReducer(
  state: EtiquetaState,
  action: EtiquetaAction
): EtiquetaState {
  // Função auxiliar para atualizar o histórico
  const atualizarHistorico = (novoTemplate: Template) => {
    // Clona o template para evitar referências compartilhadas
    const templateClonado = clonarTemplate(novoTemplate);

    // Limita o tamanho do histórico
    const novoPassado = [
      ...state.historico.passado,
      clonarTemplate(state.historico.presente),
    ];
    if (novoPassado.length > MAX_HISTORICO) {
      novoPassado.shift();
    }

    // Atualiza o elemento selecionado se necessário
    let novoElementoSelecionado = state.elementoSelecionado;
    if (state.elementoSelecionado) {
      const elementoAtualizado = templateClonado.elementos.find(
        (elem) => elem.id === state.elementoSelecionado?.id
      );
      if (elementoAtualizado) {
        novoElementoSelecionado = elementoAtualizado;
      }
    }

    return {
      ...state,
      template: templateClonado,
      elementoSelecionado: novoElementoSelecionado,
      historico: {
        passado: novoPassado,
        presente: templateClonado,
        futuro: [],
      },
    };
  };

  switch (action.type) {
    case "SET_TEMPLATE":
      return atualizarHistorico(action.payload);

    case "SET_ELEMENTO_SELECIONADO":
      return { ...state, elementoSelecionado: action.payload };

    case "SET_PRODUTO_EXEMPLO":
      return { ...state, produtoExemplo: action.payload };

    case "SET_MOSTRAR_GRID":
      return { ...state, mostrarGrid: action.payload };

    case "SET_GRID_SIZE":
      return { ...state, gridSize: action.payload };

    case "SET_TAMANHO_SELECIONADO":
      return { ...state, tamanhoSelecionado: action.payload };

    case "ADICIONAR_ELEMENTO": {
      const novoTemplate = {
        ...state.template,
        elementos: [...state.template.elementos, action.payload],
      };
      return atualizarHistorico(novoTemplate);
    }

    case "REMOVER_ELEMENTO": {
      const novoTemplate = {
        ...state.template,
        elementos: state.template.elementos.filter(
          (elem) => elem.id !== action.payload
        ),
      };
      return atualizarHistorico(novoTemplate);
    }

    case "ATUALIZAR_ELEMENTO": {
      const novoTemplate = {
        ...state.template,
        elementos: state.template.elementos.map((elem) => {
          if (elem.id === action.payload.id) {
            console.log("element found");
            return {
              ...elem,
              ...action.payload,
              tamanho: {
                width:
                  action.payload.tamanho?.width ?? elem.tamanho?.width ?? 100,
                height:
                  action.payload.tamanho?.height ?? elem.tamanho?.height ?? 30,
              },
              estilo: {
                ...elem.estilo,
                ...action.payload.estilo,
              },
            };
          }
          return elem;
        }),
      };
      return atualizarHistorico(novoTemplate);
    }

    case "ATUALIZAR_DIMENSOES": {
      const novoTemplate = {
        ...state.template,
        largura: action.payload.largura,
        altura: action.payload.altura,
      };
      return atualizarHistorico(novoTemplate);
    }

    case "ATUALIZAR_DPMM": {
      const novoTemplate = {
        ...state.template,
        dpmm: action.payload,
      };
      return atualizarHistorico(novoTemplate);
    }

    case "ATUALIZAR_COLUNAS": {
      const novoTemplate = {
        ...state.template,
        colunas: action.payload.colunas,
        espacamentoColunas: action.payload.espacamento,
      };
      return atualizarHistorico(novoTemplate);
    }

    case "DESFAZER": {
      if (state.historico.passado.length === 0) return state;

      const [ultimoPassado, ...novoPassado] = state.historico.passado;
      const templateClonado = clonarTemplate(ultimoPassado);

      return {
        ...state,
        template: templateClonado,
        historico: {
          passado: novoPassado,
          presente: templateClonado,
          futuro: [
            clonarTemplate(state.historico.presente),
            ...state.historico.futuro,
          ],
        },
      };
    }

    case "REFAZER": {
      if (state.historico.futuro.length === 0) return state;

      const [proximoFuturo, ...novoFuturo] = state.historico.futuro;
      const templateClonado = clonarTemplate(proximoFuturo);

      return {
        ...state,
        template: templateClonado,
        historico: {
          passado: [
            ...state.historico.passado,
            clonarTemplate(state.historico.presente),
          ],
          presente: templateClonado,
          futuro: novoFuturo,
        },
      };
    }

    default:
      return state;
  }
}

interface EtiquetaProviderProps {
  children: ReactNode;
  initialTemplate?: Template;
  initialProdutoExemplo?: Produto;
}

export function EtiquetaProvider({
  children,
  initialTemplate,
  initialProdutoExemplo,
}: EtiquetaProviderProps) {
  const [state, dispatch] = useReducer(etiquetaReducer, {
    ...initialState,
    template: initialTemplate
      ? clonarTemplate(initialTemplate)
      : initialState.template,
    produtoExemplo: initialProdutoExemplo,
    historico: {
      passado: [],
      presente: initialTemplate
        ? clonarTemplate(initialTemplate)
        : initialState.template,
      futuro: [],
    },
  });

  const podeDesfazer = state.historico.passado.length > 0;
  const podeRefazer = state.historico.futuro.length > 0;

  return (
    <EtiquetaContext.Provider
      value={{ state, dispatch, podeDesfazer, podeRefazer }}
    >
      {children}
    </EtiquetaContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useEtiqueta() {
  const context = useContext(EtiquetaContext);
  if (context === undefined) {
    throw new Error("useEtiqueta deve ser usado dentro de um EtiquetaProvider");
  }
  return context;
}
