import dayjs from "dayjs";

interface PeriodoDetails {
  start_date: string;
  end_date: string;
  previus_start_date: string;
  previus_end_date: string;
}

export function getPeriodo(periodo: string): PeriodoDetails {
  let start_date = null;
  let end_date = null;
  let previus_start_date = null;
  let previus_end_date = null;

  switch (periodo) {
    case "hoje":
      start_date = dayjs().startOf("day");
      end_date = dayjs().endOf("day");
      previus_start_date = dayjs().subtract(1, "day").startOf("day");
      previus_end_date = dayjs().subtract(1, "day").endOf("day");
      break;
    case "ontem":
      start_date = dayjs().subtract(1, "day").startOf("day");
      end_date = dayjs().subtract(1, "day").endOf("day");
      previus_start_date = dayjs().subtract(2, "day").startOf("day");
      previus_end_date = dayjs().subtract(2, "day").endOf("day");
      break;
    case "esta_semana":
      start_date = dayjs().startOf("week");
      end_date = dayjs().endOf("week");
      previus_start_date = dayjs().subtract(1, "week").startOf("week");
      previus_end_date = dayjs().subtract(1, "week").endOf("week");
      break;
    case "semana_passada":
      start_date = dayjs().subtract(1, "week").startOf("week");
      end_date = dayjs().subtract(1, "week").endOf("week");
      previus_start_date = dayjs().subtract(2, "week").startOf("week");
      previus_end_date = dayjs().subtract(2, "week").endOf("week");
      break;
    case "este_mes":
      start_date = dayjs().startOf("month");
      end_date = dayjs().endOf("month");
      previus_start_date = dayjs().subtract(1, "month").startOf("month");
      previus_end_date = dayjs().subtract(1, "month").endOf("month");
      break;
    case "mes_passado":
      start_date = dayjs().subtract(1, "month").startOf("month");
      end_date = dayjs().subtract(1, "month").endOf("month");
      previus_start_date = dayjs().subtract(2, "month").startOf("month");
      previus_end_date = dayjs().subtract(2, "month").endOf("month");
      break;
    case "este_ano":
      start_date = dayjs().startOf("year");
      end_date = dayjs().endOf("year");
      previus_start_date = dayjs().subtract(1, "year").startOf("year");
      previus_end_date = dayjs().subtract(1, "year").endOf("year");
      break;
    case "ano_passado":
      start_date = dayjs().subtract(1, "year").startOf("year");
      end_date = dayjs().subtract(1, "year").endOf("year");
      previus_start_date = dayjs().subtract(2, "year").startOf("year");
      previus_end_date = dayjs().subtract(2, "year").endOf("year");
      break;
  }

  const today = dayjs();
  if (end_date && end_date.isAfter(today)) {
    end_date = today;
  }

  if (previus_end_date && previus_end_date.isAfter(today)) {
    previus_end_date = today;
  }

  return {
    start_date: start_date ? start_date.format() : "",
    end_date: end_date ? end_date.format() : "",
    previus_start_date: previus_start_date ? previus_start_date.format() : "",
    previus_end_date: previus_end_date ? previus_end_date.format() : "",
  };
}
