import NuvelGrid from "components/nuvel/Grid";
import NuvelIconPicker from "components/nuvel/IconPicker";
import NuvelSelect from "components/nuvel/Select";
import NuvelTextField from "components/nuvel/TextField";
import { NuvelDefaultForm } from "components/nuvel/forms";
import { FormasDePagamentoModel } from "data/models/financeiro";
import React, { useEffect } from "react";

const formasIds = [
  { label: "Dinheiro", value: 1 },
  { label: "Cheque", value: 2 },
  { label: "Cartão de Crédito", value: 3 },
  { label: "Cartão de Débito", value: 4 },
  { label: "Crédito Loja", value: 5 },
  { label: "Vale Alimentação", value: 10 },
  { label: "Vale Refeição", value: 11 },
  { label: "Vale Presente", value: 12 },
  { label: "Vale Combustível", value: 13 },
  { label: "Boleto Bancário", value: 15 },
  { label: "Depósito Bancário", value: 16 },
  { label: "Pagamento Instantâneo (PIX)", value: 17 },
  { label: "Transferência bancária, Carteira Digital", value: 18 },
  { label: "Programa de fidelidade, Cashback, Crédito Virtual", value: 19 },
  { label: "Convênio", value: 201 },
  { label: "Outras", value: 99 },
];

const RetFormasDePagamentoForm: React.FC = () => {
  const [state, setState] = React.useState<
    typeof FormasDePagamentoModel.interface
  >({
    finalizadora: 1,
    forma: "",
    icone: "",
  });

  const verifyForma = (text: string) => {
    return formasIds.map((f) => f.label).includes(text);
  };

  useEffect(() => {
    if (
      (state.forma === "" ||
        state.forma === null ||
        state.forma === undefined ||
        verifyForma(state.forma)) &&
      state.finalizadora !== null
    ) {
      setState({
        ...state,
        forma:
          formasIds.find((f) => f.value === state.finalizadora)?.label || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.finalizadora]);

  return (
    <NuvelDefaultForm
      model={FormasDePagamentoModel}
      state={state}
      setState={setState}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={12}>
          <NuvelTextField
            label="Forma de pagamento"
            value={state.forma}
            onChange={(e) => setState({ ...state, forma: e.target.value })}
          />
        </NuvelGrid>
        <NuvelGrid xs={6}>
          <NuvelSelect
            label="Finalizadora"
            value={state.finalizadora}
            onChange={(e) =>
              setState({ ...state, finalizadora: Number(e.target.value) })
            }
            options={formasIds}
          />
        </NuvelGrid>
        <NuvelGrid xs={6}>
          <NuvelIconPicker
            name="icone"
            onChange={(e) => setState({ ...state, icone: e.target.value })}
            value={state.icone}
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelDefaultForm>
  );
};

export default RetFormasDePagamentoForm;
