import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelGrid from "components/nuvel/Grid";
import NuvelModal from "components/nuvel/Modal";
import NuvelTextField from "components/nuvel/TextField";
import { TransacaoSerializer } from "data/interfaces/financeiro/TransacaoSerializer";
import { TransacaoModel } from "data/models";
import dayjs from "dayjs";
import { useAppContext } from "hooks";
import React, { useState } from "react";
import { handleChange } from "utils/functions";

interface IModalDebito {
  id: number;
  modal: {
    id: number;
    open: boolean;
  };
  handleModal: (id: number) => void;
  mutate: () => void;
}

interface IState extends TransacaoSerializer {
  conta_entrada?: number | string;
  loja?: number | string;
}

const defaultState: IState = {
  descricao: "",
  valor: 0,
  parceiro_de_negocios: undefined,
  tipo: 4,
  status: 1,
  prestacoes: [],
  emissao: dayjs(),
  vencimento: dayjs(),
  conta_entrada: undefined,
  loja: undefined,
};

const ModalDebito: React.FC<IModalDebito> = ({
  id,
  modal,
  handleModal,
  mutate,
}) => {
  const [state, setState] = useState<IState>(defaultState);
  const { showSnack } = useAppContext();

  const handleSave = () => {
    TransacaoModel.save({
      loja: state.loja,
      parceiro_de_negocios: state.parceiro_de_negocios,
      tipo: 4,
      status: 1,
      emissao: state.emissao,
      vencimento: state.vencimento,
      valor: state.valor,
      descricao: state.descricao,
      prestacoes: [
        {
          valor: state.valor,
          vencimento: state.vencimento,
          descricao: state.descricao,
          numero: 1,
          status: 1,
          faturamentos: [
            {
              valor: state.valor,
              conta_entrada: state.conta_entrada,
              descricao: state.descricao,
              prestacao: 1,
              tipo_faturamento: 3,
            },
          ],
        },
      ],
    }).then(() => {
      mutate();
      showSnack("Operação realizada com sucesso", 2, "success");
      handleCancel();
    });
  };

  const handleCancel = () => {
    setState(defaultState);
    handleModal(id);
  };

  return (
    <NuvelModal
      open={id === modal.id && modal.open}
      title="Adicionar Débito"
      btnSave={() => handleSave()}
      btnCancel={() => handleModal(id)}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelTextField
            label="Descrição"
            name="descricao"
            value={state.descricao}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelAutocomplete
            label="Funcionário"
            name="parceiro_de_negocios"
            value={Number(state.parceiro_de_negocios)}
            onChange={(e) => handleChange(e, setState)}
            reference="funcionario"
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelAutocomplete
            label="Conta de Entrada"
            name="conta_entrada"
            value={Number(state.conta_entrada)}
            onChange={(e) => handleChange(e, setState)}
            reference="conta_bancaria"
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelTextField
            label="Valor"
            name="valor"
            value={state.valor}
            onChange={(e) => handleChange(e, setState)}
            money
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelModal>
  );
};

export default ModalDebito;
