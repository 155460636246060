/* eslint-disable @typescript-eslint/no-unused-vars */
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import PrintIcon from "@mui/icons-material/Print";
import RedoIcon from "@mui/icons-material/Redo";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import { EtiquetasModel } from "data/models";
import { useAppContext } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useNavigate, useParams } from "react-router-dom";
import { generateEtiquetaPDF } from "utils/functions/renderizarEtiqueta";
import ElementoEtiqueta from "./ElementoEtiqueta";
import { useEtiqueta } from "./EtiquetaContext";
import EtiquetaGrid from "./EtiquetaGrid";
import PainelPropriedades from "./PainelPropriedades";
import { ElementoBase, EtiquetaEditorProps } from "./types";
import { mmToPixels, pixelsToMm } from "./utils";

const elementosDisponiveis = [
  { tipo: "texto", nome: "Texto (Nome do Produto)", campo: "produto_nome" },
  { tipo: "codigoBarras", nome: "Código de Barras", campo: "cod_barra" },
  {
    tipo: "codigoBarrasSemTexto",
    nome: "Código de Barras (Sem Texto)",
    campo: "cod_barra",
  },
  {
    tipo: "textoCodigoBarras",
    nome: "Texto do Código de Barras",
    campo: "cod_barra",
  },
  { tipo: "preco", nome: "Preço", campo: "preco_venda" },
  { tipo: "codigoProduto", nome: "Código do Produto", campo: "codigo_produto" },
];

// Tamanhos predefinidos em mm
const tamanhosPreDefinidos = [
  { id: "personalizado", nome: "Personalizado", largura: 0, altura: 0 },
  { id: "pequena", nome: "Pequena (30x12mm)", largura: 30, altura: 12 },
  { id: "media", nome: "Média (50x30mm)", largura: 50, altura: 30 },
  { id: "grande", nome: "Grande (100x50mm)", largura: 100, altura: 50 },
];

const steps: Step[] = [
  {
    target: ".tour-tamanho-etiqueta",
    content:
      "Aqui você pode selecionar o tamanho predefinido da etiqueta ou configurar um tamanho personalizado.",
    placement: "right",
    disableBeacon: true,
    spotlightPadding: 5,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target: ".tour-elementos-disponiveis",
    content:
      "Nesta área você encontra todos os elementos que podem ser adicionados à etiqueta, como texto, código de barras e preço.",
    placement: "right",
    spotlightPadding: 5,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target: ".tour-area-edicao",
    content:
      "Esta é a área de edição da etiqueta. Você pode arrastar, redimensionar e rotacionar os elementos aqui.",
    placement: "left",
    spotlightPadding: 5,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target: ".tour-propriedades",
    content:
      "Quando um elemento está selecionado, você pode ajustar suas propriedades aqui, como fonte, tamanho e alinhamento.",
    placement: "left",
    spotlightPadding: 5,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target: ".tour-acoes",
    content:
      "Aqui você encontra ações importantes como salvar, voltar e desfazer/refazer",
    placement: "bottom",
    spotlightPadding: 5,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target: ".tour-preview",
    content:
      "Selecione um produto para visualizar como a etiqueta ficará quando impressa.",
    placement: "top",
    spotlightPadding: 5,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];

const EtiquetaEditor: React.FC<EtiquetaEditorProps> = () => {
  const { state, dispatch, podeDesfazer, podeRefazer } = useEtiqueta();
  const {
    template,
    elementoSelecionado,
    produtoExemplo,
    mostrarGrid,
    gridSize,
    tamanhoSelecionado,
  } = state;

  const containerRef = useRef<HTMLDivElement>(null);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [templateNome, setTemplateNome] = useState("");
  const [templateDescricao, setTemplateDescricao] = useState("");
  const [salvando, setSalvando] = useState(false);
  const [zoom, setZoom] = useState(1);
  const navigate = useNavigate();
  const { showSnack } = useAppContext();

  const { id } = useParams();

  const [runTour, setRunTour] = useState(false);
  const [tourStepIndex, setTourStepIndex] = useState(0);
  const [isTourReady, setIsTourReady] = useState(false);

  // Adiciona um pequeno delay para garantir que os elementos estejam renderizados
  useEffect(() => {
    if (runTour) {
      const timer = setTimeout(() => {
        setIsTourReady(true);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setIsTourReady(false);
    }
  }, [runTour]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunTour(false);
      setTourStepIndex(0);
      setIsTourReady(false);
    }

    if (type === "step:after") {
      // Força um pequeno delay entre os passos
      setTimeout(() => {
        setTourStepIndex((prevIndex) => prevIndex + 1);
      }, 100);
    }
  };

  useEffect(() => {
    const carregarTemplate = async () => {
      if (!id) return;
      if (id === "novo") return;

      try {
        const res = await EtiquetasModel.get(Number(id));

        // Converte as dimensões de mm para pixels
        const novoTemplate = {
          id: res.id,
          nome: res.nome,
          largura: mmToPixels(
            res.configuracao.dimensoes.largura,
            res.configuracao.dimensoes.dpmm
          ),
          altura: mmToPixels(
            res.configuracao.dimensoes.altura,
            res.configuracao.dimensoes.dpmm
          ),
          dpmm: res.configuracao.dimensoes.dpmm,
          elementos: res.configuracao.elementos.map((elem: ElementoBase) => ({
            ...elem,
            posicao: {
              x: mmToPixels(elem.posicao.x, res.configuracao.dimensoes.dpmm),
              y: mmToPixels(elem.posicao.y, res.configuracao.dimensoes.dpmm),
            },
            tamanho: {
              width: mmToPixels(
                elem.tamanho?.width || 100,
                res.configuracao.dimensoes.dpmm
              ),
              height: mmToPixels(
                elem.tamanho?.height || 30,
                res.configuracao.dimensoes.dpmm
              ),
            },
          })),
          colunas: res.configuracao.colunas,
          espacamentoColunas: res.configuracao.espacamentoColunas,
        };

        dispatch({ type: "SET_TEMPLATE", payload: novoTemplate });
        setTemplateNome(res.nome);
        setTemplateDescricao(res.descricao || "");
      } catch (error) {
        console.error("Erro ao carregar template:", error);
        showSnack("Erro ao carregar template", 2, "error");
      }
    };

    carregarTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Adiciona o listener para o evento de duplicação
  useEffect(() => {
    const handleDuplicarElemento = (event: CustomEvent) => {
      const { elemento, focus } = event.detail;

      // Primeiro desseleciona o elemento atual
      dispatch({ type: "SET_ELEMENTO_SELECIONADO", payload: null });

      // Adiciona o novo elemento
      dispatch({ type: "ADICIONAR_ELEMENTO", payload: elemento });

      // Seleciona o novo elemento
      dispatch({ type: "SET_ELEMENTO_SELECIONADO", payload: elemento });

      // Se focus for true, rola a view para o novo elemento após um pequeno delay
      if (focus) {
        setTimeout(() => {
          const novoElemento = document.querySelector(
            `[data-element-id="${elemento.id}"]`
          );
          if (novoElemento) {
            novoElemento.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            // Força o foco no novo elemento
            (novoElemento as HTMLElement).focus();
          }
        }, 100);
      }
    };

    window.addEventListener(
      "duplicarElemento",
      handleDuplicarElemento as EventListener
    );

    return () => {
      window.removeEventListener(
        "duplicarElemento",
        handleDuplicarElemento as EventListener
      );
    };
  }, [dispatch]);

  const handleTamanhoChange = (tamanhoId: string) => {
    dispatch({ type: "SET_TAMANHO_SELECIONADO", payload: tamanhoId });
    const tamanho = tamanhosPreDefinidos.find((t) => t.id === tamanhoId);
    if (tamanho && tamanhoId !== "personalizado") {
      dispatch({
        type: "ATUALIZAR_DIMENSOES",
        payload: {
          largura: mmToPixels(tamanho.largura, template.dpmm),
          altura: mmToPixels(tamanho.altura, template.dpmm),
        },
      });
    }
  };

  const handleDimensaoChange = (
    dimensao: "largura" | "altura",
    valorMm: number
  ) => {
    dispatch({
      type: "ATUALIZAR_DIMENSOES",
      payload: {
        largura:
          dimensao === "largura"
            ? mmToPixels(valorMm, template.dpmm)
            : template.largura,
        altura:
          dimensao === "altura"
            ? mmToPixels(valorMm, template.dpmm)
            : template.altura,
      },
    });
    dispatch({ type: "SET_TAMANHO_SELECIONADO", payload: "personalizado" });
  };

  const removerElemento = (elementoId: string) => {
    dispatch({ type: "REMOVER_ELEMENTO", payload: elementoId });
  };

  const adicionarElemento = (tipo: string) => {
    const novoElemento: ElementoBase = {
      id: `elemento-${template.elementos.length + 1}`,
      tipo: tipo as "texto" | "codigoBarras" | "preco",
      campo: elementosDisponiveis.find((e) => e.tipo === tipo)?.campo || "",
      posicao: { x: 10, y: 10 },
      estilo: {
        fontSize: 12,
        textAlign: "left",
        color: "#000",
      },
    };

    dispatch({ type: "ADICIONAR_ELEMENTO", payload: novoElemento });
  };

  const atualizarElemento = (elementoAtualizado: ElementoBase) => {
    console.log(elementoAtualizado);
    dispatch({
      type: "ATUALIZAR_ELEMENTO",
      payload: {
        ...elementoAtualizado,
        tamanho: {
          width: elementoAtualizado.tamanho?.width ?? 100,
          height: elementoAtualizado.tamanho?.height ?? 30,
        },
        estilo: {
          ...(elementoAtualizado.estilo ?? {}),
          fontSize: elementoAtualizado.estilo?.fontSize ?? 12,
          textAlign: elementoAtualizado.estilo?.textAlign ?? "left",
          fontWeight: elementoAtualizado.estilo?.fontWeight ?? "normal",
          fontStyle: elementoAtualizado.estilo?.fontStyle ?? "normal",
          textDecoration: elementoAtualizado.estilo?.textDecoration ?? "none",
          fontFamily: elementoAtualizado.estilo?.fontFamily ?? "Arial",
        },
      },
    });
  };

  // Função para limpar seleção
  const limparSelecao = () => {
    dispatch({ type: "SET_ELEMENTO_SELECIONADO", payload: null });
  };

  // Event listener para a tecla ESC, Delete e atalhos de desfazer/refazer
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        limparSelecao();
      }
      // Delete para remover elemento selecionado
      if (event.key === "Delete" && elementoSelecionado) {
        event.preventDefault();
        removerElemento(elementoSelecionado.id);
        showSnack("Elemento removido", 2, "info");
      }
      // Ctrl+Z para desfazer
      if (event.ctrlKey && event.key === "z" && !event.shiftKey) {
        event.preventDefault();
        if (podeDesfazer) {
          dispatch({ type: "DESFAZER" });
        }
      }
      // Ctrl+Shift+Z ou Ctrl+Y para refazer
      if (
        (event.ctrlKey && event.shiftKey && event.key === "z") ||
        (event.ctrlKey && event.key === "y")
      ) {
        event.preventDefault();
        if (podeRefazer) {
          dispatch({ type: "REFAZER" });
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    podeDesfazer,
    podeRefazer,
    elementoSelecionado,
    removerElemento,
    showSnack,
  ]);

  // Função para clicar fora e limpar seleção
  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      limparSelecao();
    }
  };

  const handleSaveTemplate = async () => {
    try {
      setSalvando(true);

      const templateData = {
        id: Number(id),
        nome: templateNome,
        descricao: templateDescricao,
        ativo: true,
        configuracao: {
          dimensoes: {
            largura: pixelsToMm(template.largura, template.dpmm),
            altura: pixelsToMm(template.altura, template.dpmm),
            dpmm: template.dpmm,
          },
          colunas: template.colunas,
          espacamentoColunas: template.espacamentoColunas,
          elementos: template.elementos.map((elem) => ({
            ...elem,
            tamanho: {
              width: pixelsToMm(elem.tamanho?.width || 100, template.dpmm),
              height: pixelsToMm(elem.tamanho?.height || 30, template.dpmm),
            },
            posicao: {
              x: pixelsToMm(elem.posicao.x, template.dpmm),
              y: pixelsToMm(elem.posicao.y, template.dpmm),
            },
          })),
        },
      };

      await EtiquetasModel.save(templateData);
      showSnack("Template salvo com sucesso!", 2, "success");
      navigate(-1);
      setOpenSaveDialog(false);
    } catch (error) {
      showSnack("Erro ao salvar template", 2, "error");
    } finally {
      setSalvando(false);
    }
  };

  const handlePrintTest = async () => {
    if (!produtoExemplo) {
      showSnack("Selecione um produto para teste", 2, "warning");
      return;
    }

    try {
      await generateEtiquetaPDF(produtoExemplo, template, 3);
    } catch (error) {
      console.error("Erro ao gerar impressão:", error);
      showSnack("Erro ao gerar impressão", 2, "error");
    }
  };

  // Função para resetar o zoom
  const resetZoom = () => {
    setZoom(1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 2,
        height: "calc(100vh - 100px)",
      }}
    >
      {isTourReady && (
        <Joyride
          steps={steps}
          run={runTour}
          stepIndex={tourStepIndex}
          continuous
          showSkipButton
          showProgress
          disableOverlayClose
          hideCloseButton
          styles={{
            options: {
              primaryColor: "#1976d2",
              zIndex: 10000,
              arrowColor: "#fff",
              backgroundColor: "#fff",
              textColor: "#333",
              overlayColor: "rgba(0, 0, 0, 0.5)",
            },
            tooltip: {
              borderRadius: 4,
              fontSize: 14,
            },
            buttonNext: {
              backgroundColor: "#1976d2",
              color: "#fff",
            },
            buttonBack: {
              color: "#1976d2",
            },
            buttonSkip: {
              color: "#666",
            },
          }}
          callback={handleJoyrideCallback}
          locale={{
            last: "Finalizar",
            next: "Próximo",
            skip: "Pular",
            back: "Voltar",
            close: "Fechar",
            nextLabelWithProgress: "Próximo ({step} de {steps})",
          }}
        />
      )}

      <Paper sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Editor de Etiquetas</Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
            className="tour-acoes"
          >
            <Button variant="contained" onClick={() => navigate(-1)}>
              Voltar
            </Button>
            <Tooltip title="Desfazer (Ctrl+Z)">
              <IconButton
                onClick={() => dispatch({ type: "DESFAZER" })}
                disabled={!podeDesfazer}
              >
                <UndoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refazer (Ctrl+Shift+Z)">
              <IconButton
                onClick={() => dispatch({ type: "REFAZER" })}
                disabled={!podeRefazer}
              >
                <RedoIcon />
              </IconButton>
            </Tooltip>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => setOpenSaveDialog(true)}
            >
              Salvar Template
            </Button>
            <Tooltip title="Iniciar Tour">
              <IconButton onClick={() => setRunTour(true)}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Paper>

      <Box sx={{ display: "flex", gap: 2, flex: 1, overflow: "hidden" }}>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 2, width: 300 }}
        >
          <Paper sx={{ p: 2 }} className="tour-tamanho-etiqueta">
            <Typography variant="h6" sx={{ mb: 2 }}>
              Configurações da Etiqueta
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <FormControl fullWidth size="small">
                <InputLabel>Tamanho Predefinido</InputLabel>
                <Select
                  value={tamanhoSelecionado}
                  label="Tamanho Predefinido"
                  onChange={(e) => handleTamanhoChange(e.target.value)}
                >
                  {tamanhosPreDefinidos.map((tamanho) => (
                    <MenuItem key={tamanho.id} value={tamanho.id}>
                      {tamanho.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                size="small"
                label="DPMM"
                type="number"
                value={template.dpmm}
                onChange={(e) =>
                  dispatch({
                    type: "ATUALIZAR_DPMM",
                    payload: Number(e.target.value),
                  })
                }
              />
              <TextField
                fullWidth
                size="small"
                label="Largura (mm)"
                type="number"
                value={pixelsToMm(template.largura, template.dpmm)}
                onChange={(e) =>
                  handleDimensaoChange("largura", Number(e.target.value))
                }
              />
              <TextField
                fullWidth
                size="small"
                label="Altura (mm)"
                type="number"
                value={pixelsToMm(template.altura, template.dpmm)}
                onChange={(e) =>
                  handleDimensaoChange("altura", Number(e.target.value))
                }
              />

              <TextField
                fullWidth
                size="small"
                label="Número de Colunas"
                type="number"
                value={template.colunas}
                onChange={(e) =>
                  dispatch({
                    type: "ATUALIZAR_COLUNAS",
                    payload: {
                      colunas: Math.max(
                        1,
                        Math.min(10, Number(e.target.value))
                      ),
                      espacamento: template.espacamentoColunas,
                    },
                  })
                }
                inputProps={{ min: 1, max: 10 }}
              />

              {template.colunas > 1 && (
                <TextField
                  fullWidth
                  size="small"
                  label="Espaçamento (mm)"
                  type="number"
                  value={template.espacamentoColunas}
                  onChange={(e) =>
                    dispatch({
                      type: "ATUALIZAR_COLUNAS",
                      payload: {
                        colunas: template.colunas,
                        espacamento: Math.max(0, Number(e.target.value)),
                      },
                    })
                  }
                  inputProps={{ min: 0, step: 0.5 }}
                />
              )}

              <FormControlLabel
                control={
                  <Switch
                    checked={mostrarGrid}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_MOSTRAR_GRID",
                        payload: e.target.checked,
                      })
                    }
                  />
                }
                label="Mostrar Grade"
              />
            </Box>
          </Paper>

          <Paper
            sx={{ p: 2, flex: 1, overflow: "auto" }}
            className="tour-elementos-disponiveis"
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Elementos Disponíveis
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {elementosDisponiveis.map((elem) => (
                <Button
                  key={elem.tipo}
                  variant="contained"
                  onClick={() => adicionarElemento(elem.tipo)}
                  fullWidth
                  size="small"
                  sx={{ justifyContent: "flex-start" }}
                >
                  {elem.nome}
                </Button>
              ))}
            </Box>
          </Paper>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flex: 1 }}>
          <Paper sx={{ p: 2 }} className="tour-area-edicao">
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography variant="body2">Zoom:</Typography>
              <Slider
                value={zoom}
                onChange={(_, value) => setZoom(value as number)}
                min={0.5}
                max={2}
                step={0.1}
                sx={{ width: 200 }}
              />
              <Typography variant="body2">{Math.round(zoom * 100)}%</Typography>
              <Button size="small" onClick={resetZoom}>
                Resetar
              </Button>
            </Box>
          </Paper>

          <Paper
            sx={{
              flex: 1,
              position: "relative",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f5f5f5",
            }}
            onClick={handleBackgroundClick}
          >
            <Box
              ref={containerRef}
              sx={{
                position: "relative",
                width: template.largura,
                height: template.altura,
                backgroundColor: "white",
                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                transform: `scale(${zoom})`,
                transformOrigin: "center center",
              }}
            >
              {mostrarGrid && (
                <EtiquetaGrid
                  width={template.largura}
                  height={template.altura}
                  spacing={gridSize}
                />
              )}
              {template.elementos.map((elemento) => (
                <ElementoEtiqueta
                  key={elemento.id}
                  elemento={elemento}
                  selecionado={elementoSelecionado?.id === elemento.id}
                  onClick={() =>
                    dispatch({
                      type: "SET_ELEMENTO_SELECIONADO",
                      payload: elemento,
                    })
                  }
                  onDragEnd={(x, y) => {
                    dispatch({
                      type: "ATUALIZAR_ELEMENTO",
                      payload: { ...elemento, posicao: { x, y } },
                    });
                  }}
                  onResize={(width, height) => {
                    dispatch({
                      type: "ATUALIZAR_ELEMENTO",
                      payload: { ...elemento, tamanho: { width, height } },
                    });
                  }}
                  onRotate={(angle) => {
                    dispatch({
                      type: "ATUALIZAR_ELEMENTO",
                      payload: { ...elemento, rotacao: angle },
                    });
                  }}
                  containerRef={containerRef}
                  produtoExemplo={produtoExemplo}
                  gridSize={gridSize}
                  zoom={zoom}
                />
              ))}
            </Box>
          </Paper>

          <Paper sx={{ p: 2 }} className="tour-preview">
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography variant="h6">Preview e Impressão:</Typography>
              <Box sx={{ flex: 1 }}>
                <NuvelAutocomplete
                  label="Selecione um produto para preview"
                  name="produto_exemplo"
                  onChange={(_e, _v, r) => {
                    if (r) {
                      dispatch({
                        type: "SET_PRODUTO_EXEMPLO",
                        payload: {
                          ...r,
                          produto_nome: r.produto?.nome || "",
                          preco_venda: Number(r.produto?.preco_venda),
                          produto: {
                            codigo: r.produto?.codigo || 0,
                          },
                        },
                      });
                    } else {
                      dispatch({
                        type: "SET_PRODUTO_EXEMPLO",
                        payload: undefined,
                      });
                    }
                  }}
                  reference="etiqueta_em_lote"
                  value={produtoExemplo?.id}
                  clearAfterSelect={false}
                />
              </Box>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<PrintIcon />}
                onClick={handlePrintTest}
                disabled={!produtoExemplo}
              >
                Imprimir Teste
              </Button>
            </Box>
          </Paper>
        </Box>

        <Paper sx={{ width: 300 }} className="tour-propriedades">
          <Box
            sx={{
              p: 2,
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">Propriedades do Elemento</Typography>
            {elementoSelecionado && (
              <Box sx={{ display: "flex", gap: 1 }}>
                <Tooltip title="Remover elemento">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => removerElemento(elementoSelecionado.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancelar seleção (ESC)">
                  <IconButton size="small" onClick={limparSelecao}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          <PainelPropriedades
            elemento={elementoSelecionado}
            onUpdate={atualizarElemento}
          />
        </Paper>
      </Box>

      <Dialog open={openSaveDialog} onClose={() => setOpenSaveDialog(false)}>
        <DialogTitle>Salvar Template de Etiqueta</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              fullWidth
              label="Nome do Template"
              value={templateNome}
              onChange={(e) => setTemplateNome(e.target.value)}
              required
            />
            <TextField
              fullWidth
              label="Descrição"
              value={templateDescricao}
              onChange={(e) => setTemplateDescricao(e.target.value)}
              multiline
              rows={3}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSaveDialog(false)} disabled={salvando}>
            Cancelar
          </Button>
          <Button
            onClick={handleSaveTemplate}
            variant="contained"
            disabled={!templateNome || salvando}
          >
            {salvando ? "Salvando..." : "Salvar"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EtiquetaEditor;
