import { Chip, Theme } from "@mui/material";
import { TableColumn } from "components/nuvel/lists";
import { CaixaPDVSerializer } from "data/interfaces/pdv/CaixaPDVSerializer";
import dayjs from "dayjs";

export const RETAGUARDA_CAIXA_LIST = (
  theme: Theme
): TableColumn<CaixaPDVSerializer>[] => [
  {
    label: "ID",
    accessor: (row) => `#${row.id}`,
    minWidth: 300,
  },
  {
    label: "Data de Abertura",
    accessor: (row) =>
      row.data_abertura &&
      dayjs(row.data_abertura).format("DD/MM/YYYY HH:mm:ss"),
    minWidth: 300,
  },
  {
    label: "Data de Fechamento",
    accessor: (row) =>
      row.data_fechamento &&
      dayjs(row.data_fechamento).format("DD/MM/YYYY HH:mm:ss"),
    minWidth: 300,
  },
  {
    label: "Status",
    accessor: (row) =>
      row.status ? (
        <Chip
          style={{
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
          }}
          label="Fechado"
          color="success"
        />
      ) : (
        <Chip
          style={{
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
          }}
          label="Aberto"
          color="warning"
        />
      ),
    minWidth: 300,
  },
  {
    label: "Operador",
    accessor: "operador",
    minWidth: 300,
  },
];

export const RETAGUARDA_CAIXA_ORDERING: (keyof CaixaPDVSerializer)[] = ["id"];
