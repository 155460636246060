/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from "react";

import {
  CardList,
  Finalizadoras,
  MaisVendidos,
  PeriodoSelect,
  TotalEstados,
  VendasPeriodo,
  VendasPresentePassado,
} from "./misc";

import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelMultipleAutocomplete from "components/nuvel/MultipleAutocomplete";
import NuvelSelect from "components/nuvel/Select";
import AuthController from "data/controller/auth";
import DashVendas from "data/controller/dashVendas";
import dayjs from "dayjs";
import FileSaver from "file-saver";
import { handleChange as handleChangeUtils } from "utils/functions";
import { getPeriodo } from "utils/functions/getPeriodo";
import DashboardDataLayer from "./data_layer";

function DashVendasGeral() {
  const [state, setState] = React.useState({
    loja: [],
    periodo: "hoje",
    periodoParams: {
      start_date: getPeriodo("hoje").start_date,
      end_date: getPeriodo("hoje").end_date,
      previus_start_date: getPeriodo("hoje").previus_start_date,
      previus_end_date: getPeriodo("hoje").previus_end_date,
    },
    status: 0,
  });
  const { data: user_data } = AuthController.useCurrentUser();
  const [qtdMaisVendidos, setQtdMaisVendidos] = React.useState(10);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((_v) => ({
      ..._v,
      periodoParams: {
        ..._v.periodoParams,
        [name]: value.format(),
      },
    }));
  };

  const isDayInterval =
    dayjs(state.periodoParams.start_date).diff(
      dayjs(state.periodoParams.end_date),
      "days"
    ) === 0;

  const download_xls = (tipo) => {
    let loja = state.loja;
    const { start_date, end_date, previus_start_date, previus_end_date } =
      state.periodoParams;

    // if (loja === 'todas') {
    //   loja = null;
    // }

    if (tipo === "simplificado") {
      DashVendas.getDownloadSimplificado({
        loja,
        start_date,
        end_date,
        previus_start_date,
        previus_end_date,
      }).then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        FileSaver.saveAs(
          blob,
          `relatorio_simplificado_${start_date}_até_${end_date}.xlsx`
        );
      });
    } else {
      if (tipo === "detalhado") {
        DashVendas.getDownloadDetalhado({
          loja,
          start_date,
          end_date,
          previus_start_date,
          previus_end_date,
        }).then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          FileSaver.saveAs(
            blob,
            `relatorio_detalhado_${start_date}_até_${end_date}.xlsx`
          );
        });
      }
    }
  };

  return (
    <DashboardDataLayer qtdMaisVendidos={qtdMaisVendidos} data={state}>
      {({
        totalPeriodo,
        histogram_v_total,
        totalFormaPagamento,
        totalEstados,
        produtosMaisVendidos,
        histograma_presente_passado,
        histogram_horario_comparativo_v_total,
      }) => (
        <>
          <NuvelGrid container spacing={1} style={{ marginBottom: 5 }}>
            <NuvelGrid xs={12} sm={4} md={3}>
              <NuvelMultipleAutocomplete
                label="Loja"
                name="loja"
                reference="loja"
                value={state.loja}
                onChange={(e) => handleChangeUtils(e, setState)}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={6} md={3}>
              <NuvelSelect
                label="Status"
                onChange={(e) => handleChangeUtils(e, setState)}
                name="status"
                value={state.status}
                options={[
                  { label: "Todos", value: -1 },
                  { label: "Realizadas", value: 0 },
                  { label: "Canceladas", value: 1 },
                  { label: "Cancelado sem finalizar", value: 2 },
                ]}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={6} md={2}>
              <PeriodoSelect state={state} setState={setState} />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={6} md={2}>
              <NuvelDatePicker
                label="Data Inicial"
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: dayjs(e.target.value).startOf("day"),
                    },
                  })
                }
                name="start_date"
                value={state.periodoParams.start_date}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={6} md={2}>
              <NuvelDatePicker
                label="Data Final"
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: dayjs(e.target.value).endOf("day"),
                    },
                  })
                }
                name="end_date"
                value={state.periodoParams.end_date}
              />
            </NuvelGrid>
          </NuvelGrid>
          <NuvelGrid container spacing={1}>
            <CardList totalPeriodo={totalPeriodo} />
            <NuvelGrid xs={12} sm={6} lg={9}></NuvelGrid>
            <NuvelGrid xs={12} sm={12} lg={6}>
              <VendasPeriodo
                data={histogram_v_total}
                isDayInterval={isDayInterval}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12} lg={6}>
              <VendasPresentePassado data={histograma_presente_passado} />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={6} lg={6}>
              <MaisVendidos
                rows={produtosMaisVendidos}
                loading={!produtosMaisVendidos}
                quantidade={qtdMaisVendidos}
                setQuantidade={setQtdMaisVendidos}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={6} lg={6}>
              <TotalEstados rows={totalEstados} />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12} lg={4} xl={4}>
              <Finalizadoras data={totalFormaPagamento} />
            </NuvelGrid>
          </NuvelGrid>
        </>
      )}
    </DashboardDataLayer>
  );
}

export default DashVendasGeral;
