import { Paper, PaperProps } from "@mui/material";
import React, { ReactNode } from "react";
import LoadingCover from "./LoadingCover";

interface PaperWithLoadProps extends PaperProps {
  loading: boolean;
  children: ReactNode;
}

const PaperWithLoad: React.FC<PaperWithLoadProps> = ({
  children,
  loading,
  ...rest
}) => {
  return (
    <Paper {...rest} sx={{ position: "relative", borderRadius: 2, ...rest.sx }}>
      {children}
      <LoadingCover enabled={loading} />
    </Paper>
  );
};

export default PaperWithLoad;
