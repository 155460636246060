import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NuvelSelect from "components/nuvel/Select";
import React from "react";
import { Permission } from "./controller";

interface PermissoesTableProps {
  data: Permission[];
  setPermissions: (permissions: Permission[]) => void;
}

const permissoes = [
  {
    label: "Ler",
    value: "R",
  },
  {
    label: "Escrever",
    value: "W",
  },
  {
    label: "Excluir",
    value: "D",
  },
];

const defaultPaddingWithSelect = { paddingTop: 8, paddingBottom: 8 };

const PermissoesTable: React.FC<PermissoesTableProps> = ({
  data,
  setPermissions,
}) => {
  const handleRowValue = (e: SelectChangeEvent<string[]>, item: Permission) => {
    let temp_data = [...data];
    temp_data = temp_data.map((perm) => {
      if (perm.id === item.id) {
        perm.value = e.target.value as string[];
      }
      if (perm.children.length > 0) {
        perm.children = perm.children.map((permChild) => {
          if (permChild.id === item.id) {
            permChild.value = e.target.value as string[];
          }
          return permChild;
        });
      }
      return perm;
    });

    setPermissions(temp_data);
  };

  const removePermission = (id: string, nodes: Permission[]): Permission[] => {
    return nodes.reduce((result: Permission[], node) => {
      if (node.id === id) return result;

      if (node.children.length > 0) {
        node.children = removePermission(id, node.children);
      }

      result.push(node);
      return result;
    }, []);
  };

  const handleRemovePermission = (id: string) => {
    const newPermissions = removePermission(id, data);
    setPermissions(newPermissions);
  };

  const renderRows = (data: Permission[], level = 0): JSX.Element[] => {
    return data.map((item) => (
      <React.Fragment key={item.id}>
        <TableRow>
          <TableCell
            style={{
              paddingLeft: level * 24 + 16,
              ...(level === 0 ? {} : defaultPaddingWithSelect),
            }}
          >
            {item.nome}
          </TableCell>
          <TableCell
            width="30%"
            style={level === 0 ? {} : defaultPaddingWithSelect}
          >
            {level === 0 ? null : (
              <NuvelSelect
                value={Array.isArray(item.value) ? item.value : []}
                onChange={(e: SelectChangeEvent<string[]>) =>
                  handleRowValue(e, item)
                }
                multiple
                variant="outlined"
                size="small"
                label="Permissões"
                options={permissoes.map((perm) => ({
                  value: perm.value,
                  label: perm.label,
                }))}
                sx={{ minWidth: 200 }}
              />
            )}
          </TableCell>
          <TableCell
            style={
              level === 0
                ? { width: 50 }
                : { ...defaultPaddingWithSelect, width: 50 }
            }
          >
            <IconButton
              onClick={() => handleRemovePermission(item.id)}
              size="small"
            >
              <DeleteIcon color="error" />
            </IconButton>
          </TableCell>
        </TableRow>
        {item.children.length > 0 && renderRows(item.children, level + 1)}
      </React.Fragment>
    ));
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Permissão</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Opções</TableCell>
            <TableCell style={{ width: 50 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderRows(data)}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default PermissoesTable;
