import {
  AddCircle,
  Cancel,
  PauseCircleFilled,
  RemoveCircle,
} from "@mui/icons-material";
import {
  CircularProgress,
  debounce,
  Divider,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import Grid from "components/nuvel/Grid";
import Modal from "components/nuvel/Modal";
import Select from "components/nuvel/Select";
import TextField from "components/nuvel/TextField";
import {
  CAMPOS_IMPOSTO_TO_VALID_NOME,
  CSOSN,
  CST_LIST,
} from "constants/shared/impostos";
import { ImpostoSerializer } from "data/interfaces/financeiro/ImpostoSerializer";
import { NotaDeSaidaItensSerializer } from "data/interfaces/financeiro/NotaDeSaidaItensSerializer";
import {
  NotaDeSaidaSerializer,
  NotaDeSaidaSerializerFinalidadeEnum,
} from "data/interfaces/financeiro/NotaDeSaidaSerializer";
import { CalculaImpostoSerializer } from "data/interfaces/sefaz/calc_imposto";
import calc_imposto from "data/models/sefaz/calc_imposto";
import React, { useCallback } from "react";
import { NuvelSelectValue } from "utils/types";
import { incluirItemState } from "../states";
import { getTotaisNota } from "../utils";
const useStyles = makeStyles((theme: Theme) => ({
  esquerda: {},
  direita: {
    borderLeft: "solid 1px rgba(0, 0, 0, 0.12)",
  },
  comum: {
    padding: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const PlusIcon = () => (
  <Grid
    xs={2}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <AddCircle fontSize="small" />
  </Grid>
);

const EqualIcon = () => (
  <Grid
    xs={2}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <PauseCircleFilled
      style={{ transform: "rotate(90deg)" }}
      fontSize="small"
    />
  </Grid>
);

const MinusIcon = () => (
  <Grid
    xs={2}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <RemoveCircle fontSize="small" />
  </Grid>
);

interface InclusaoDeItemProps {
  stateNotaItem: NotaDeSaidaItensSerializer;
  setStateNota: React.Dispatch<React.SetStateAction<NotaDeSaidaSerializer>>;
  setStateNotaItem: React.Dispatch<
    React.SetStateAction<NotaDeSaidaItensSerializer>
  >;
  stateNota: NotaDeSaidaSerializer;
  modalSec: { id: number; open: boolean };
  handleSecModal: (id: number) => void;
}

function InclusaoDeItem(props: InclusaoDeItemProps) {
  const {
    stateNotaItem,
    setStateNotaItem,
    setStateNota,
    stateNota,
    modalSec,
    handleSecModal,
  } = props;
  const classes = useStyles();
  const [impostoPreview, setImpostoPreview] =
    React.useState<CalculaImpostoSerializer | null>(null);
  const [loadingImposto, setLoadingImposto] = React.useState(false);

  const onClose = () => {
    setStateNotaItem(incluirItemState);
    setImpostoPreview(null);
    handleSecModal(1);
  };

  const recalculaImposto = async (
    prod_state: NotaDeSaidaItensSerializer,
    emitente: number,
    imposto_obj: ImpostoSerializer
  ) => {
    if (!emitente || !prod_state.produto || !imposto_obj?.id) {
      setImpostoPreview({
        error: "Selecione um emitente, um produto e um imposto",
      });
      return;
    }
    setLoadingImposto(true);
    calc_imposto
      .get(undefined, {
        imposto: imposto_obj?.id,
        produto: prod_state.produto,
        loja: emitente,
        preco_total: prod_state.subtotal,
        desconto: prod_state.calc_desc,
        acrescimo: prod_state.calc_acres,
        frete: prod_state.frete,
        is_nfe: true,
        p_devol: prod_state.porcentagem_devolvida,
      })
      .then((res) => {
        setImpostoPreview(res);
        setLoadingImposto(false);
      })
      .catch((err) => {
        setImpostoPreview({
          error: `Erro ao calcular imposto: ${
            err.response?.data?.detail ?? err.response?.data?.message
          }`,
        });
        setLoadingImposto(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recalculaImpostoDelayed = useCallback(
    debounce(
      (
        prod_state: NotaDeSaidaItensSerializer,
        emitente: number,
        imposto_obj: ImpostoSerializer
      ) => recalculaImposto(prod_state, emitente, imposto_obj),
      1000
    ),
    [] // será criada apenas uma vez inicialmente
  );

  const typeToFormat = (type: string, value: number) => {
    switch (type) {
      case "R":
        return value.toBRL();
      case "P":
        return `${value.toDecimal()}%`;
      default:
        return value.toString();
    }
  };

  const getImpostoValues = () => {
    const obj: {
      nome: string;
      valor: number;
      valor_formatado: string;
    }[] = [];
    if (!impostoPreview) return obj;
    Object.keys(impostoPreview).forEach((key) => {
      if (
        CAMPOS_IMPOSTO_TO_VALID_NOME[
          key as keyof typeof CAMPOS_IMPOSTO_TO_VALID_NOME
        ]
      ) {
        obj.push({
          nome: CAMPOS_IMPOSTO_TO_VALID_NOME[
            key as keyof typeof CAMPOS_IMPOSTO_TO_VALID_NOME
          ].split(";")[0],
          valor: Number(impostoPreview[key as keyof typeof impostoPreview]),
          valor_formatado: typeToFormat(
            CAMPOS_IMPOSTO_TO_VALID_NOME[
              key as keyof typeof CAMPOS_IMPOSTO_TO_VALID_NOME
            ].split(";")[1],
            Number(impostoPreview[key as keyof typeof impostoPreview])
          ),
        });
      }
    });
    return obj;
  };

  const recalculaItem = (stateNotaItem: NotaDeSaidaItensSerializer) => {
    if (!stateNotaItem.produto)
      return {
        subtotal: 0,
        calc_desc: 0,
        calc_acres: 0,
        total: 0,
      };
    const subtotal =
      stateNotaItem.preco_venda * (stateNotaItem.quantidade || 0);
    const calc_desc = ((stateNotaItem.desconto || 0) / 100) * subtotal;
    const calc_acres = ((stateNotaItem.acrescimo | 0) / 100) * subtotal;
    const total =
      subtotal + calc_acres - calc_desc + Number(stateNotaItem.frete || 0);
    const final_obj = {
      ...stateNotaItem,
      subtotal: subtotal.round(2),
      calc_desc: calc_desc.round(2),
      calc_acres: calc_acres.round(2),
      total: total.round(2),
    };
    if (!stateNotaItem.imposto_obj || !stateNota.emitente) return final_obj;
    recalculaImpostoDelayed(
      final_obj,
      Number(stateNota.emitente),
      stateNotaItem.imposto_obj
    );
    return final_obj;
  };

  const updateStateAndRecalculate = (
    newState: Partial<NotaDeSaidaItensSerializer>
  ) => {
    // @ts-expect-error aaaaaa
    setStateNotaItem((prevState) => {
      const final_obj: NotaDeSaidaItensSerializer = {
        ...prevState,
        ...newState,
      };
      return recalculaItem(final_obj);
    });
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | {
          target: {
            name: string;
            value: NuvelSelectValue | boolean;
          };
        }
  ) => {
    updateStateAndRecalculate({
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    if (!stateNotaItem.produto) return handleSecModal(1);

    const itensTemp = [...stateNota.itens];

    const idx = itensTemp.findIndex((x) => x.produto === stateNotaItem.produto);
    if (idx > -1) {
      itensTemp[idx] = stateNotaItem;
    } else {
      itensTemp.push(stateNotaItem);
    }

    setStateNota((_v) => ({
      ..._v,
      itens: itensTemp,
      ...getTotaisNota(itensTemp, stateNota.pagamentos),
    }));
    onClose();
  };

  const saveDisabled = () => {
    return (
      !stateNotaItem.produto ||
      !stateNotaItem.quantidade ||
      !stateNotaItem.imposto ||
      !stateNotaItem.total ||
      !stateNotaItem.subtotal
    );
  };

  return (
    <Modal
      fullWidth
      minWidth="lg"
      maxWidth="lg"
      open={modalSec.id === 1 ? modalSec.open : false}
      title="Inclusão de Item"
      btnCancel={() => {
        onClose();
      }}
      btnSave={handleSave}
      btnSaveDisabled={saveDisabled()}
    >
      <Grid container>
        <Grid xs={12} sm={5} className={`${classes.esquerda} ${classes.comum}`}>
          <Grid container spacing={1}>
            <Grid xs={12} sm={12}>
              <NuvelAutocomplete
                label="Produto"
                name="produto"
                value={Number(stateNotaItem.produto)}
                onChange={(_e, _select, fullValue) => {
                  if (fullValue?.imposto) {
                    updateStateAndRecalculate({
                      imposto: fullValue.imposto ?? "",
                      nome: fullValue.nome ?? "",
                      produto: fullValue.id ?? "",
                      preco_venda: fullValue.preco_venda ?? 0,
                    });
                  } else {
                    updateStateAndRecalculate({
                      imposto: "",
                      imposto_obj: undefined,
                      nome: fullValue?.nome ?? "",
                      produto: fullValue?.id ?? "",
                      preco_venda: fullValue?.preco_venda ?? 0,
                    });
                    setImpostoPreview(null);
                  }
                }}
                reference="produto"
              />
            </Grid>
            <Grid xs={11} sm={3}>
              <TextField
                fullWidth
                decimal
                min={0.0001}
                variant="filled"
                label="Quant."
                color="primary"
                name="quantidade"
                value={stateNotaItem.quantidade}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Cancel fontSize="small" />
            </Grid>
            <Grid xs={10} sm={8}>
              <TextField
                fullWidth
                variant="filled"
                label="Valor Unitário"
                color="primary"
                name="preco_venda"
                value={stateNotaItem.preco_venda}
                onChange={(e) => handleChange(e)}
                money
              />
            </Grid>
            <EqualIcon />
            <Grid xs={10}>
              <TextField
                fullWidth
                variant="filled"
                readOnly
                label="Subtotal"
                color="primary"
                name="subtotal"
                value={stateNotaItem.subtotal}
                money
              />
            </Grid>
            <MinusIcon />
            <Grid xs={3}>
              <TextField
                fullWidth
                variant="filled"
                perc
                label="Desconto"
                color="primary"
                name="desconto"
                value={stateNotaItem.desconto}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid xs={7}>
              <TextField
                fullWidth
                variant="filled"
                readOnly
                label="Total Desconto"
                color="primary"
                name="calc_desc"
                value={stateNotaItem.calc_desc}
              />
            </Grid>
            <PlusIcon />
            <Grid xs={3}>
              <TextField
                fullWidth
                variant="filled"
                perc
                label="Acréscimo"
                color="primary"
                name="acrescimo"
                value={stateNotaItem.acrescimo}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid xs={7}>
              <TextField
                fullWidth
                variant="filled"
                readOnly
                label="Total Acréscimo"
                color="primary"
                name="calc_acres"
                value={stateNotaItem.calc_acres}
              />
            </Grid>
            <PlusIcon />
            <Grid xs={10}>
              <TextField
                fullWidth
                variant="filled"
                money
                label="Frete"
                color="primary"
                name="frete"
                value={stateNotaItem.frete}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <EqualIcon />
            <Grid xs={10}>
              <TextField
                fullWidth
                variant="filled"
                money
                readOnly
                label="Total"
                color="primary"
                name="total"
                value={stateNotaItem.total}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} sm={7} className={`${classes.direita} ${classes.comum}`}>
          <Grid container spacing={1}>
            <Grid xs={12}>
              <NuvelAutocomplete
                label="Imposto"
                name="imposto"
                value={stateNotaItem.imposto}
                onChange={(e) => handleChange(e)}
                reference="imposto"
                onFetchCurrentObject={(imposto_obj) => {
                  updateStateAndRecalculate({
                    imposto_obj,
                  });
                }}
              />
            </Grid>

            <Grid xs={6}>
              <Select
                label="CST"
                name="cst"
                disabled
                value={stateNotaItem.imposto_obj?.cst || ""}
                onChange={(e) => handleChange(e)}
                options={CST_LIST}
              />
            </Grid>
            <Grid xs={3}>
              <TextField
                disabled
                fullWidth
                variant="filled"
                label="CFOP"
                color="primary"
                name="cfop"
                value={stateNotaItem.imposto_obj?.cfop || ""}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid xs={3}>
              <Select
                disabled
                label="CSOSN"
                name="csosn"
                value={stateNotaItem.imposto_obj?.csosn || ""}
                onChange={(e) => handleChange(e)}
                options={CSOSN}
              />
            </Grid>
            {stateNota.finalidade ===
              NotaDeSaidaSerializerFinalidadeEnum["Devolução/Retorno"] && (
              <Grid xs={5}>
                <TextField
                  fullWidth
                  variant="filled"
                  perc
                  label="Perc. Devolvida"
                  color="primary"
                  name="porcentagem_devolvida"
                  value={stateNotaItem.porcentagem_devolvida}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
            )}
            <Grid xs={12}>
              <Divider />
            </Grid>
            {loadingImposto ? (
              <Grid
                xs={12}
                style={{
                  textAlign: "center",
                  marginTop: "20%",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={64} />
              </Grid>
            ) : (
              <Grid xs={12}>
                <Typography style={{ padding: 2 }} variant="body2">
                  Pré-visualização de impostos (Considerar como 0 caso não
                  exiba)
                </Typography>
                {impostoPreview?.error ? (
                  <Typography style={{ padding: 2 }} variant="body1">
                    {impostoPreview.error}
                  </Typography>
                ) : (
                  getImpostoValues()
                    .filter((x) => Number(x.valor) > 0)
                    .map((x, i) => (
                      <Typography
                        style={{ padding: 2 }}
                        variant="body1"
                        key={i}
                      >
                        {x.nome}: {x.valor_formatado}
                      </Typography>
                    ))
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default InclusaoDeItem;
