import {
  ElementoBase,
  Produto,
  Template,
} from "components/retaguarda/etiqueta/types";
import { mmToPixels } from "components/retaguarda/etiqueta/utils";
import { EtiquetasSerializer } from "data/interfaces/core/EtiquetasSerializer";
import { EtiquetaEmLoteSerializer } from "data/interfaces/estoque/EtiquetaEmLoteSerializer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { textToBase64Barcode } from "./textToBase64Barcode";

type ItemEtiqueta = (Produto | EtiquetaEmLoteSerializer) & {
  quantidade?: number;
};

export function converterEtiquetaParaTemplate(
  etiqueta: EtiquetasSerializer
): Template {
  return {
    id: etiqueta.id?.toString() || "",
    nome: etiqueta.nome,
    largura: etiqueta.configuracao.dimensoes.largura,
    altura: etiqueta.configuracao.dimensoes.altura,
    dpmm: etiqueta.configuracao.dimensoes.dpmm,
    elementos: etiqueta.configuracao.elementos,
    colunas: etiqueta.configuracao.colunas,
    espacamentoColunas: etiqueta.configuracao.espacamentoColunas,
  };
}

export function renderizarEtiqueta(
  item: ItemEtiqueta,
  template: Template,
  quantidade: number = 1
): HTMLDivElement[] {
  const etiquetas: HTMLDivElement[] = [];

  // Calcular quantas linhas precisamos
  const totalLinhas = quantidade;

  // Processa cada linha
  for (let linha = 0; linha < totalLinhas; linha++) {
    // Criar container para esta linha
    const containerLinha = document.createElement("div");
    containerLinha.style.display = "flex";
    containerLinha.style.width = `${
      template.largura * template.colunas +
      template.espacamentoColunas * template.dpmm * (template.colunas - 1)
    }px`;

    // Calcula quantas etiquetas precisamos nesta linha
    const etiquetasNestaLinha = template.colunas;

    // Adiciona as etiquetas para esta linha
    for (let coluna = 0; coluna < etiquetasNestaLinha; coluna++) {
      const container = document.createElement("div");
      container.style.cssText = `
        width: ${template.largura}px;
        height: ${template.altura}px;
        position: relative;
        background-color: #ffffff;
        overflow: hidden;
        flex-shrink: 0;
      `;

      const fragmento = document.createDocumentFragment();

      // Multiplica os elementos pela quantidade de colunas
      for (let i = 0; i < template.colunas; i++) {
        template.elementos.forEach((elemento) => {
          const div = document.createElement("div");
          div.style.cssText = `
            position: absolute;
            left: ${elemento.posicao.x + i * template.largura}px;
            top: ${elemento.posicao.y}px;
            width: ${elemento.tamanho?.width || 100}px;
            height: ${elemento.tamanho?.height || 30}px;
            overflow: hidden;
            font-size: ${elemento.estilo?.fontSize || 12}px;
            text-align: ${elemento.estilo?.textAlign || "left"};
            font-weight: ${elemento.estilo?.fontWeight || "normal"};
            transform: ${
              elemento.rotacao ? `rotate(${elemento.rotacao}deg)` : "none"
            };
            transform-origin: center center;
          `;
          div.style.color = elemento.estilo?.color || "#000";

          console.log(item);

          switch (elemento.tipo) {
            case "texto":
              div.textContent = String(
                item[elemento.campo as keyof ItemEtiqueta] || ""
              );
              break;
            case "preco": {
              const preco = Number(
                item[elemento.campo as keyof ItemEtiqueta] || 0
              );
              div.textContent = preco.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
              break;
            }
            case "codigoBarras": {
              const img = new Image();
              img.src = textToBase64Barcode(
                String(item[elemento.campo as keyof ItemEtiqueta] || ""),
                12,
                30
              );
              img.style.cssText =
                "width: 100%; height: 100%; object-fit: contain;";
              div.appendChild(img);
              break;
            }
            case "codigoBarrasSemTexto": {
              const img = new Image();
              img.src = textToBase64Barcode(
                String(item[elemento.campo as keyof ItemEtiqueta] || ""),
                12,
                30,
                false
              );
              img.style.cssText =
                "width: 100%; height: 100%; object-fit: contain;";
              div.appendChild(img);
              break;
            }
            case "textoCodigoBarras": {
              div.textContent = String(
                item[elemento.campo as keyof ItemEtiqueta] || ""
              );
              break;
            }
            case "codigoProduto": {
              div.textContent = `COD ${item.produto?.codigo || ""}`;
              break;
            }
          }

          fragmento.appendChild(div);
        });
      }

      container.appendChild(fragmento);
      containerLinha.appendChild(container);

      // Adiciona espaçamento após cada etiqueta, exceto a última
      if (coluna < etiquetasNestaLinha - 1) {
        const containerSpacing = document.createElement("div");
        containerSpacing.style.width = `${Math.round(
          template.espacamentoColunas * template.dpmm
        )}px`;
        containerSpacing.style.height = `${template.altura}px`;
        containerLinha.appendChild(containerSpacing);
      }
    }

    etiquetas.push(containerLinha);
  }

  return etiquetas;
}

export function renderizarEtiquetasEmLote(
  produtos: Produto[],
  template: Template,
  colunas: number,
  espacamentoColunas: number
) {
  const containerPrincipal = document.createElement("div");
  containerPrincipal.style.display = "flex";
  containerPrincipal.style.width = `${
    template.largura * colunas + espacamentoColunas * (colunas - 1)
  }px`;

  produtos.forEach((produto, index) => {
    const etiqueta = renderizarEtiqueta(produto, template)[0];
    containerPrincipal.appendChild(etiqueta);

    if (index < produtos.length - 1) {
      const espaco = document.createElement("div");
      espaco.style.width = `${espacamentoColunas}px`;
      containerPrincipal.appendChild(espaco);
    }
  });

  return containerPrincipal;
}

export async function generateEtiquetaPDF(
  itens: ItemEtiqueta | ItemEtiqueta[],
  template: Template,
  quantidade: number = 1,
  notNewTemplate: boolean = false
) {
  if (notNewTemplate) {
    template = convertSizesOfTemplate(template);
  }
  // Converte para array se for um único item
  const itensArray = Array.isArray(itens) ? itens : [itens];

  // Renderiza todas as etiquetas
  const todasEtiquetas = itensArray.flatMap((item) => {
    console.log(item);
    return renderizarEtiqueta(item, template, item?.quantidade || quantidade);
  });

  console.log(todasEtiquetas);

  // Calcular dimensões em milímetros
  const larguraEtiquetaMm = template.largura / template.dpmm;
  const alturaEtiquetaMm = template.altura / template.dpmm;
  const espacamentoMm = template.espacamentoColunas;

  // Calcular dimensões totais em milímetros para uma linha
  const larguraTotalMm =
    larguraEtiquetaMm * template.colunas +
    espacamentoMm * (template.colunas - 1);
  const alturaTotalMm = alturaEtiquetaMm;

  // Cria o PDF
  const pdf = new jsPDF({
    orientation: larguraTotalMm > alturaTotalMm ? "landscape" : "portrait",
    unit: "mm",
    format: [larguraTotalMm, alturaTotalMm],
    compress: true,
  });

  // Processa cada etiqueta como uma página separada
  for (let i = 0; i < todasEtiquetas.length; i++) {
    // Adiciona o container ao DOM
    document.body.appendChild(todasEtiquetas[i]);

    // Converte para canvas
    const canvas = await html2canvas(todasEtiquetas[i], {
      scale: template.dpmm,
      backgroundColor: "#ffffff",
      logging: false,
      useCORS: true,
      allowTaint: true,
      imageTimeout: 0,
      width: todasEtiquetas[i].offsetWidth,
      height: todasEtiquetas[i].offsetHeight,
      onclone: (clonedDoc) => {
        const elements = clonedDoc.getElementsByTagName("*");
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i] as HTMLElement;
          if (element.style) {
            element.style.transform = "none";
          }
        }
      },
    });

    // Remove o container do DOM
    document.body.removeChild(todasEtiquetas[i]);

    // Se não for a primeira página, adiciona uma nova página
    if (i > 0) {
      pdf.addPage();
    }

    // Adiciona a imagem ao PDF
    pdf.addImage(
      canvas.toDataURL("image/jpeg", 0.8),
      "JPEG",
      0,
      0,
      larguraTotalMm,
      alturaTotalMm,
      undefined,
      "FAST"
    );
  }

  pdf.autoPrint();
  pdf.output("dataurlnewwindow");
}

const convertSizesOfTemplate = (template: Template) => {
  return {
    id: template.id,
    nome: template.nome,
    largura: mmToPixels(template.largura, template.dpmm),
    altura: mmToPixels(template.altura, template.dpmm),
    dpmm: template.dpmm,
    elementos: template.elementos.map((elem: ElementoBase) => ({
      ...elem,
      posicao: {
        x: mmToPixels(elem.posicao.x, template.dpmm),
        y: mmToPixels(elem.posicao.y, template.dpmm),
      },
      tamanho: {
        width: mmToPixels(elem.tamanho?.width || 100, template.dpmm),
        height: mmToPixels(elem.tamanho?.height || 30, template.dpmm),
      },
    })),
    colunas: template.colunas,
    espacamentoColunas: template.espacamentoColunas,
  };
};
