import { Typography, useTheme } from "@mui/material";
import NuvelGrid from "components/nuvel/Grid";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

export default function Finalizadoras({ ...props }) {
  const { data } = props;
  const theme = useTheme();

  const availableColors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.tertiary.main,
    theme.palette.text.primary,
    "#E6BA57",
    "#B38547",
    "#F29A24",
    "#4C7F9D",
    "#73624B",
    "#3B4348",
    "#687CA6",
    "#5784E6",
    "#00C49F",
    "#FF8042",
    "#CC0000",
    "#FFD700",
    "#6A5ACD",
    "#FF1493",
    "#32CD32",
    "#FF4500",
    "#9370DB",
    "#20B2AA",
    "#FF69B4",
    "#3CB371",
    "#4B0082",
    // Other 100 random colors
    ...Array.from({ length: 100 }, () => {
      return `rgb(${[1, 2, 3].map((x) => (Math.random() * 256) | 0)})`;
    }),
  ];

  let _data = [];

  const formatCurrency = (value) => {
    return value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  };

  if (data && data.length > 0) {
    data.forEach((element) => {
      _data.push({
        value: element.valor_total,
        labels: element.f_pagamento,
        labels_flat: element.f_pagamento_flat,
        text: formatCurrency(element.valor_total),
      });
    });
  }

  const renderCustomLabel = (item) => {
    return (
      <text
        fill={item.fill}
        x={item.x}
        y={item.y}
        stroke="none"
        alignmentBaseline="middle"
        className="recharts-text recharts-pie-label-text"
        textAnchor="end"
      >
        <tspan x={item.x} textAnchor={item.textAnchor} dy="0em">
          {item.payload.text}
        </tspan>
      </text>
    );
  };
  return (
    <>
      <PaperWithLoad loading={!data} style={{ width: "100%", height: "24em" }}>
        <NuvelGrid container>
          <NuvelGrid
            style={{
              width: "100%",
              height: "3em",
              textAlign: "center",
            }}
            xs={12}
          >
            <Typography variant="h5" component="h2">
              Finalizadoras
            </Typography>
          </NuvelGrid>
          <NuvelGrid style={{ width: "100%", height: "21em" }} xs={12}>
            {_data.length > 0 && (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    isAnimationActive={true}
                    dataKey="value"
                    data={_data}
                    label={(item) => renderCustomLabel(item)}
                    cx={"50%"}
                    cy={"50%"}
                    outerRadius={120}
                  >
                    {_data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={availableColors[index]}
                      />
                    ))}
                  </Pie>
                  <Legend
                    formatter={(value, entry) =>
                      `${entry.payload.payload.labels}`
                    }
                  />
                  <Tooltip
                    labelFormatter={(value) => value}
                    formatter={(value, order, entry) => [
                      `${value.toBRL()}`,
                      entry?.payload?.payload?.labels,
                    ]}
                  />
                </PieChart>
              </ResponsiveContainer>
            )}
          </NuvelGrid>
        </NuvelGrid>
      </PaperWithLoad>
    </>
  );
}
