/* eslint-disable react-hooks/rules-of-hooks */
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ClickAwayListener,
  Collapse,
  Divider,
  Grow,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  ListItemTextProps,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import usePermissions from "hooks/usePermissions";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCollapseStates } from "utils/functions/routes";
import { RouteInterface } from "utils/router";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
  height: 52,
}));

// eslint-disable-next-line react-refresh/only-export-components
export const useDrawerState = (routes: RouteInterface[], open: boolean) => {
  const [state, setState] = useState<{ [key: string]: boolean }>({});
  const [anchorEls, setAnchorEls] = useState<{
    [key: string]: HTMLElement | null;
  }>({});
  useEffect(() => {
    const initialCollapseStates = getCollapseStates(routes);
    setState(initialCollapseStates);
  }, [routes]);

  useEffect(() => {
    if (open) {
      const initialCollapseStates = getCollapseStates(routes);
      setState(initialCollapseStates);
    } else {
      setState({});
      setAnchorEls({});
    }
  }, [open, routes]);

  const changeState = (st: { [key: string]: boolean }) => {
    setState((prevState) => ({ ...prevState, ...st }));
  };

  const setAnchor = (key: string, anchor: HTMLElement | null) => {
    setAnchorEls((prevState) => ({ ...prevState, [key]: anchor }));
  };

  return { state, changeState, anchorEls, setAnchor };
};

interface ListItemButtonStyledProps extends ListItemButtonProps {
  active?: boolean;
  open?: boolean;
  collapseOpen?: boolean;
  subMenuActive?: boolean;
}

const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== "active" &&
    prop !== "open" &&
    prop !== "collapseOpen" &&
    prop !== "subMenuActive",
})<ListItemButtonStyledProps>(({ theme }) => ({
  height: theme.spacing(4.5),

  backgroundColor: "transparent",

  color: theme.palette.text.primary,

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },

  borderTopRightRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2),
  marginBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1.5),

  transition: theme.transitions.create(
    ["background-color", "color", "margin", "padding", "border"],
    {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }
  ),

  variants: [
    {
      props: ({ collapseOpen }) => collapseOpen,
      style: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    {
      props: ({ collapseOpen }) => collapseOpen,
      style: {
        "&:hover": {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
    },
    {
      props: ({ subMenuActive }) => subMenuActive,
      style: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    {
      props: ({ subMenuActive }) => subMenuActive,
      style: {
        "&:hover": {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
    },
    {
      props: ({ active }) => active,

      style: {
        backgroundColor: theme.palette.primary.main,
      },
    },
    {
      props: ({ active }) => active,

      style: {
        color: "white",
      },
    },
    {
      props: ({ active }) => active,

      style: {
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    {
      props: ({ open }) => !open,

      style: {
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    {
      props: ({ open }) => !!open,

      style: {
        paddingLeft: theme.spacing(2.5),
        marginRight: theme.spacing(2),
      },
    },
  ],
}));

interface ListItemIconStyledProps extends ListItemIconProps {
  active?: boolean;
  open?: boolean;
  collapseOpen?: boolean;
}

const ListItemIconStyled = styled(ListItemIcon, {
  shouldForwardProp: (prop) =>
    prop !== "active" && prop !== "open" && prop !== "collapseOpen",
})<ListItemIconStyledProps>(({ theme }) => ({
  minWidth: 44,

  transition: theme.transitions.create(["width", "min-width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  color:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 0.74)"
      : "rgba(0, 0, 0, 0.54)",

  variants: [
    {
      props: ({ active }) => active,

      style: {
        color: "white",
      },
    },
    {
      props: ({ collapseOpen }) => collapseOpen,

      style: {
        color: "white",
      },
    },
    {
      props: ({ open }) => open,

      style: {
        minWidth: 36,
      },
    },
  ],
}));

interface ListItemTextStyledProps extends ListItemTextProps {
  active?: boolean;
  open?: boolean;
}

const ListItemTextStyled = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "open",
})<ListItemTextStyledProps>(({ theme }) => ({
  transition: theme.transitions.create(["opacity"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  opacity: 0,

  variants: [
    {
      props: ({ open }) => open,

      style: {
        opacity: 1,
      },
    },
  ],
}));

interface MenuItemStyledProps extends MenuItemProps {
  active?: boolean;
}

const MenuItemStyled = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== "active",
})<MenuItemStyledProps>(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },

  borderRadius: theme.spacing(2),
  marginTop: theme.spacing(0.5),
  backgroundColor: "transparent",
  color: theme.palette.text.primary,

  variants: [
    {
      props: ({ active }) => active,

      style: {
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    {
      props: ({ active }) => active,

      style: {
        backgroundColor: theme.palette.primary.main,
      },
    },
    {
      props: ({ active }) => active,

      style: {
        color: "white",
      },
    },
  ],
}));

const MenuTitle = styled("span")(({ theme }) => ({
  fontSize: 16,

  color: "rgba(0, 0, 0, 0.74)",

  fontWeight: 500,
  paddingLeft: 12,

  ...theme.applyStyles("dark", {
    color: "rgba(255, 255, 255, 0.74)",
  }),
}));

const ListStyled = styled(List)(({ theme }) => ({
  position: "relative",
  "&:before": {
    content: '" "',
    height: "100%",
    width: 1,
    position: "absolute",

    background: "rgba(0, 0, 0, 0.3)",

    left: "10%",

    ...theme.applyStyles("dark", {
      background: "rgba(255, 255, 255, 0.3)",
    }),
  },
}));

// eslint-disable-next-line react-refresh/only-export-components
export const createLinks = (
  routes: RouteInterface[],
  state: { [key: string]: boolean },
  changeState: (st: { [key: string]: boolean }) => void,
  open?: boolean,
  subMenu?: boolean,
  isPopper?: boolean,
  anchorEls?: { [key: string]: HTMLElement | null },
  setAnchor?: (key: string, anchor: HTMLElement | null) => void,
  fatherAnchorKey?: string | undefined
) => {
  const navigate = useNavigate();
  const location = useLocation();

  return routes.map((prop, key) => {
    if (prop.norender || prop.loader) {
      return null;
    }

    const { read } = usePermissions(prop.perm || "");

    if (!read) {
      return null;
    }

    const isAnyChildActive = (
      children: RouteInterface[],
      pathname: string
    ): boolean => {
      return children.some((child) => {
        if (child.path === pathname) {
          return true;
        }
        if (child.children) {
          return isAnyChildActive(child.children, pathname);
        }
        return false;
      });
    };

    const isActive =
      location.pathname.startsWith(prop.path) ||
      (prop.children && isAnyChildActive(prop.children, location.pathname));

    const RouteIcon = prop.icon ? React.cloneElement(prop.icon) : null;
    const anchorEl = anchorEls ? anchorEls[prop.state!] : null;
    const collapseOpen = state[prop.state!] || Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      if (!open && prop.children && setAnchor) {
        setAnchor(prop.state!, anchorEl ? null : event.currentTarget);
      } else if (open && prop.state) {
        const allStatesToFalse = Object.keys(state).reduce(
          (acc, curr) => ({ ...acc, [curr]: false }),
          {}
        );
        changeState({
          ...allStatesToFalse,
          [prop.state!]: !state[prop.state!],
        });
      } else {
        navigate(prop.path);
      }
    };

    const handleClose = () => {
      if (setAnchor)
        setAnchor((fatherAnchorKey as string) || prop.state!, null);
    };

    if (prop.state) {
      return (
        <React.Fragment key={key}>
          <Tooltip
            disableHoverListener={open}
            title={prop.title}
            placement="right"
          >
            <ListItemButtonStyled
              active={isActive}
              onClick={handleClick}
              open={open}
              collapseOpen={collapseOpen}
            >
              <ListItemIconStyled
                collapseOpen={collapseOpen}
                open={open}
                active={isActive}
              >
                {RouteIcon}
              </ListItemIconStyled>
              <ListItemTextStyled open={open} primary={prop.title} />
              {state[prop.state!] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButtonStyled>
          </Tooltip>
          {prop.children && !open && (
            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              role={undefined}
              transition
              placement="right-start"
              sx={{ zIndex: 9999 }}
              modifiers={[{ name: "offset", options: { offset: [0, 16] } }]}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper
                    elevation={4}
                    sx={{ p: 1, pt: 0, pb: 0, minWidth: 220, borderRadius: 2 }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem>
                        <MenuTitle>{prop.title}</MenuTitle>
                        <Divider />
                        {createLinks(
                          prop.children!,
                          state,
                          changeState,
                          open,
                          true,
                          true,
                          anchorEls,
                          setAnchor,
                          prop.state!
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )}
          <Collapse timeout="auto" in={state[prop.state!]} unmountOnExit>
            <ListStyled style={{ padding: 0 }}>
              {createLinks(
                prop.children!,
                state,
                changeState,
                open,
                true,
                false,
                anchorEls,
                setAnchor
              )}
            </ListStyled>
          </Collapse>
        </React.Fragment>
      );
    }

    if (isPopper) {
      return (
        <MenuItemStyled
          active={isActive}
          key={key}
          onClick={() => {
            navigate(prop.path);
            handleClose();
          }}
          disabled={prop.disabled}
        >
          <ListItemText primary={prop.title} />
        </MenuItemStyled>
      );
    }

    return (
      <React.Fragment key={key}>
        <ListItemButtonStyled
          active={!subMenu ? isActive : false}
          subMenuActive={subMenu && isActive}
          key={key}
          onClick={() => navigate(prop.path)}
          open={open}
          disabled={prop.disabled}
        >
          <ListItemIconStyled open={open} active={!subMenu ? isActive : false}>
            {!subMenu ? RouteIcon : null}
          </ListItemIconStyled>
          <ListItemTextStyled
            open={open}
            primary={prop.title}
            disableTypography={true}
          />
        </ListItemButtonStyled>
      </React.Fragment>
    );
  });
};

// eslint-disable-next-line react-refresh/only-export-components
export const openedMixin = (theme: Theme): CSSObject => ({
  width: 280,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: `1px solid ${theme.palette.divider}`,
});

// eslint-disable-next-line react-refresh/only-export-components
export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  borderRight: `1px solid ${theme.palette.divider}`,
});
