import { Button } from "@mui/material";
import LegacySimpleTable from "components/legacy/legacy_simple_list";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import Grid from "components/nuvel/Grid";
import TabPanel from "components/nuvel/TabPanel";
import TextField from "components/nuvel/TextField";
import { FormaSerializer } from "data/interfaces/financeiro/FormaSerializer";
import { NotaDeSaidaSerializer } from "data/interfaces/financeiro/NotaDeSaidaSerializer";
import { useState } from "react";
import { getTotaisNota } from "../../utils";
interface PagamentoTabProps {
  tabs: number;
  stateNota: NotaDeSaidaSerializer;
  setStateNota: React.Dispatch<React.SetStateAction<NotaDeSaidaSerializer>>;
  disabled: boolean;
}

interface FormaPagamento {
  forma: FormaSerializer | null;
  valor: number;
  troco: number;
}

const PagamentoTab = ({
  tabs,
  stateNota,
  setStateNota,
  disabled,
}: PagamentoTabProps) => {
  const [formaPagamento, setFormaPagamento] = useState<FormaPagamento>({
    forma: null,
    valor: 0,
    troco: 0,
  });

  const onRemovePagamento = (index: number) => {
    if (disabled) return;
    const newPags = stateNota.pagamentos.filter((_, i) => i !== index);
    setStateNota((prev) => ({
      ...prev,
      pagamentos: newPags,
      ...getTotaisNota(prev.itens, newPags),
    }));
  };

  const onAddPagamento = () => {
    if (disabled) return;
    if (formaPagamento.forma?.id) {
      const newPags = [
        ...stateNota.pagamentos,
        {
          forma: formaPagamento.forma?.id as number,
          forma_nome: formaPagamento.forma?.forma as string,
          valor: formaPagamento.valor,
          troco: formaPagamento.troco,
        },
      ];
      setStateNota((prev) => ({
        ...prev,
        pagamentos: newPags,
        ...getTotaisNota(prev.itens, newPags),
      }));
      setFormaPagamento({
        forma: null,
        valor: 0,
        troco: 0,
      });
    }
  };

  return (
    <TabPanel value={tabs} index={3}>
      <Grid container spacing={1}>
        <Grid size={12}>
          <Grid container spacing={1}>
            <Grid
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <NuvelAutocomplete
                label="Forma de Pagamento"
                name="forma_pagamento"
                reference="forma_pagamento"
                value={formaPagamento.forma?.id}
                onChange={(_e, _v, fullValue) => {
                  if (fullValue) {
                    setFormaPagamento((prev) => ({
                      ...prev,
                      forma: fullValue,
                    }));
                  }
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <TextField
                label="Valor"
                name="valor"
                type="number"
                value={formaPagamento.valor}
                onChange={(e) =>
                  // @ts-expect-error - TODO: fix this, it only accepts numbers
                  setFormaPagamento((prev) => ({
                    ...prev,
                    valor: e.target.value,
                  }))
                }
                disabled={disabled}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <TextField
                label="Troco"
                name="troco"
                type="number"
                value={formaPagamento.troco}
                onChange={(e) =>
                  // @ts-expect-error - TODO: fix this, it only accepts numbers
                  setFormaPagamento((prev) => ({
                    ...prev,
                    troco: e.target.value,
                  }))
                }
                disabled={disabled}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onAddPagamento}
                sx={{
                  width: "100%",
                  height: "100%",
                }}
                disabled={disabled}
              >
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          size={12}
          sx={{
            minHeight: "150px",
          }}
        >
          <LegacySimpleTable
            columns={[
              {
                accessor: "forma_nome",
                label: "Forma",
              },
              {
                accessor: "valor",
                label: "Valor",
              },
              {
                accessor: "troco",
                label: "Troco",
              },
            ]}
            rows={stateNota.pagamentos}
            onRowClick={(_row, index) => onRemovePagamento(index)}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default PagamentoTab;
