import { PriceTabFormatter } from "./formatter";
import { TerminalDePrecoFormatter } from "./types";

export const createTerminalDePrecoFormatter = (type: "PRICETAB"): TerminalDePrecoFormatter => {
  switch (type) {
    case "PRICETAB":
      return new PriceTabFormatter();
    default:
      throw new Error(`Formato de terminal de preço não suportado: ${type}`);
  }
};
