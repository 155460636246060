import { Delete } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import { NuvelRawForm } from "components/nuvel/forms";
import NuvelGrid from "components/nuvel/Grid";
import { SimpleList } from "components/nuvel/lists";
import { EtiquetasSerializer } from "data/interfaces/core/EtiquetasSerializer";
import { EtiquetaEmLoteSerializer } from "data/interfaces/estoque/EtiquetaEmLoteSerializer";
import React from "react";
import {
  converterEtiquetaParaTemplate,
  generateEtiquetaPDF,
} from "utils/functions/renderizarEtiqueta";

interface StateInterface {
  produtos: (EtiquetaEmLoteSerializer & { quantidade: number })[];
  produto_selecionado: string | number | undefined;
  etiqueta_selecionada: EtiquetasSerializer | undefined;
}

const RetEtiquetaEmLoteForm: React.FC = () => {
  const [state, setState] = React.useState<StateInterface>({
    produtos: [],
    produto_selecionado: undefined,
    etiqueta_selecionada: undefined,
  });

  const onSubmit = (stopLoading: () => void) => {
    generateEtiquetaPDF(
      state.produtos.map((x) => ({
        ...x,
        preco_venda: x.produto?.preco_venda,
        produto_nome: x.produto?.nome,
      })),
      converterEtiquetaParaTemplate(
        state.etiqueta_selecionada as EtiquetasSerializer
      ),
      1,
      true
    );
    stopLoading();
    return;
  };

  return (
    <NuvelRawForm preventOnEnter onSubmit={onSubmit} title="Etiqueta em Lote">
      <NuvelGrid container spacing={1}>
        <NuvelGrid size={{ xs: 12, md: 4 }}>
          <NuvelAutocomplete
            label="Etiqueta"
            name="etiqueta_selecionada"
            reference="etiquetas"
            value={state.etiqueta_selecionada?.id}
            onChange={(_e, _v, r) => {
              if (r) {
                setState({
                  ...state,
                  etiqueta_selecionada: r as EtiquetasSerializer,
                });
              }
            }}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12, md: 8 }}>
          <NuvelAutocomplete
            label="Produto"
            name="produto_selecionado"
            onChange={(_e, _v, r) => {
              if (r) {
                const temp_produtos = [...state.produtos];
                if (!temp_produtos.find((x) => x.id === r.id)) {
                  temp_produtos.push({ ...r, quantidade: 1 });
                  setState({
                    ...state,
                    produtos: temp_produtos,
                  });
                }
              }
            }}
            reference="etiqueta_em_lote"
            value={state.produto_selecionado}
            clearAfterSelect
            filter={{
              id__not_in: state.produtos.map((x) => x.id).join(","),
            }}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <SimpleList
            columns={[
              {
                label: "Produto",
                accessor: "produto_nome",
              },
              {
                label: "Quantidade",
                accessor: (
                  _row: EtiquetaEmLoteSerializer & { quantidade: number },
                  index: number
                ) => (
                  <TextField
                    type="number"
                    value={state.produtos[index].quantidade}
                    onChange={(e) => {
                      const newProdutos = [...state.produtos];
                      newProdutos[index].quantidade =
                        parseInt(e.target.value) || 1;
                      setState({ ...state, produtos: newProdutos });
                    }}
                    style={{ minWidth: 100 }}
                  />
                ),
              },
              {
                label: "",
                accessor: (_row: EtiquetaEmLoteSerializer, index: number) => (
                  <IconButton
                    size="small"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        produtos: prev.produtos.filter((_p, i) => i !== index),
                      }));
                    }}
                    sx={(theme) => ({
                      border: `1px solid ${theme.palette.error.main}`,
                    })}
                  >
                    <Delete color="error" />
                  </IconButton>
                ),
                align: "right",
              },
            ]}
            data={state.produtos}
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelRawForm>
  );
};

export default RetEtiquetaEmLoteForm;
