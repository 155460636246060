import { Button } from "@mui/material";
import { NuvelRawForm } from "components/nuvel/forms";
import NuvelGrid from "components/nuvel/Grid";
import { SimpleList } from "components/nuvel/lists";
import NuvelSelect from "components/nuvel/Select";
import { ProdutoModel } from "data/models";
import { saveAs } from "file-saver";
import React from "react";
import { createTerminalDePrecoFormatter } from "utils/functions/terminal_de_preco";
import ModalTesteExportacao from "./testeExportacao";
const RetExportarTerminalDePrecoForm: React.FC = () => {
  const [testeExportacao, setTesteExportacao] = React.useState(false);
  const { data: listaProdutos } = ProdutoModel.useModel({
    page_size: 9999,
  });
  const [values, setValues] = React.useState({
    listaProdutos: [],
    formatoSelecionado: "",
  });
  const { formatoSelecionado } = values;

  const salvarExportacao = (stopLoading: () => void) => {
    if (!listaProdutos) return stopLoading();

    const finalProds = listaProdutos.rows.filter(
      (prod) =>
        prod.codbarras &&
        prod.codbarras.length > 0 &&
        Number(prod.preco_venda) > 0 &&
        prod.nome
    );

    try {
      const formatter = createTerminalDePrecoFormatter(
        formatoSelecionado as "PRICETAB"
      );

      console.log(finalProds);

      const linhas = finalProds
        .map((prod) =>
          formatter.formatLine({
            name: prod.nome,
            code: prod.codbarras[0]?.cod_barra,
            prices: [Number(prod.preco_venda)],
          })
        )
        .join("\n");

      const blob = new Blob([linhas], { type: "text/plain;charset=utf-8" });
      saveAs(blob, `PRICETAB.txt`);
    } catch (error) {
      console.error("Erro ao formatar arquivo:", error);
    } finally {
      stopLoading();
    }
  };

  return (
    <NuvelRawForm
      title={() => (
        <span>
          Exportar Terminal de Preço -{" "}
          <Button
            variant="outlined"
            onClick={() => setTesteExportacao(true)}
            disabled={testeExportacao}
          >
            Testar Exportação
          </Button>
        </span>
      )}
      onSubmit={(stopLoading) => salvarExportacao(stopLoading)}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={12}>
          <NuvelSelect
            label="Modelo"
            value={formatoSelecionado}
            onChange={(e) =>
              setValues({ ...values, formatoSelecionado: e.target.value })
            }
            options={[{ label: "PRICETAB", value: "PRICETAB" }]}
          />
        </NuvelGrid>
        <NuvelGrid xs={12}>
          <SimpleList<typeof ProdutoModel.interface_list>
            columns={[{ accessor: "nome", label: "Descrição" }]}
            data={listaProdutos?.rows ? listaProdutos?.rows : []}
          />
        </NuvelGrid>
      </NuvelGrid>
      <ModalTesteExportacao
        open={testeExportacao}
        onClose={() => setTesteExportacao(false)}
      />
    </NuvelRawForm>
  );
};

export default RetExportarTerminalDePrecoForm;
