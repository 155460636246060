import { GenericTerminalDePrecoFormatterType, TerminalDePrecoFormatter } from "./types";


export class PriceTabFormatter implements TerminalDePrecoFormatter {
  private formatPrice(price: number): string {
    return price
      .toFixed(2)
  }

  formatLine({
    name,
    code,
    prices,
  }: GenericTerminalDePrecoFormatterType): string {
    const formattedPrices = prices.map(price => this.formatPrice(price)).join("|");

    return `${code}|${name}|${formattedPrices}`;
  }

  parseLine(line: string): GenericTerminalDePrecoFormatterType {
    const values = line.split("|");
    const name = values[1];
    const code = values[0];
    const prices = values.slice(2).map(price => parseFloat(price));

    return {
      code,
      name,
      prices
    };
  }
}

