/* eslint-disable @typescript-eslint/no-explicit-any */

import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { textToBase64Barcode } from "../../../utils/functions/textToBase64Barcode";
import { ElementoBase, Produto } from "./types";

interface Props {
  elemento: ElementoBase;
  selecionado: boolean;
  onClick: () => void;
  onDragEnd: (x: number, y: number) => void;
  onResize: (width: number, height: number) => void;
  onRotate: (angle: number) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  produtoExemplo?: Produto;
  gridSize?: number;
  zoom?: number;
}

const ElementoEtiqueta: React.FC<Props> = ({
  elemento,
  selecionado,
  onClick,
  onDragEnd,
  onResize,
  onRotate,
  containerRef,
  produtoExemplo,
  gridSize = 10,
  zoom = 1,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const resizing = useRef(false);
  const startPos = useRef({ x: 0, y: 0 });
  const startSize = useRef({ width: 0, height: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [showGuides, setShowGuides] = useState(false);
  const [guidePosition, setGuidePosition] = useState({ x: 0, y: 0 });
  const theme = useTheme();
  const textColor = theme.palette.common.black;

  useEffect(() => {
    if (selecionado && elementRef.current) {
      elementRef.current.focus();
    }
  }, [selecionado]);

  const snapToGrid = (value: number): number => {
    return Math.round(value / gridSize) * gridSize;
  };

  const centralizarElemento = () => {
    if (containerRef.current && elementRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      const elementWidth = elementRef.current.offsetWidth;
      const elementHeight = elementRef.current.offsetHeight;

      const x = snapToGrid((containerWidth - elementWidth) / 2);
      const y = snapToGrid((containerHeight - elementHeight) / 2);

      onDragEnd(x, y);
    }
  };

  const ajustarTamanhoAoConteudo = () => {
    if (contentRef.current) {
      const { width, height } = contentRef.current.getBoundingClientRect();
      onResize(snapToGrid(width + 8), snapToGrid(height + 8)); // +8 para padding
    }
  };

  useEffect(() => {
    if (contentRef.current && elemento.ajustarAoConteudo) {
      ajustarTamanhoAoConteudo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produtoExemplo, elemento.ajustarAoConteudo]);

  const renderControles = () => {
    if (!selecionado) return null;

    return (
      <Box
        sx={{
          position: "absolute",
          top: -40,
          right: 0,
          display: "flex",
          gap: 0.5,
          backgroundColor: "white",
          padding: "2px",
          borderRadius: "4px",
          boxShadow: "0 0 5px rgba(0,0,0,0.2)",
          zIndex: 2,
        }}
      >
        <Tooltip title="Girar 90° anti-horário">
          <IconButton
            size="small"
            onClick={() => {
              const currentRotation = elemento.rotacao || 0;
              onRotate(currentRotation - 90);
            }}
          >
            <RotateLeftIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Girar 90° horário">
          <IconButton
            size="small"
            onClick={() => {
              const currentRotation = elemento.rotacao || 0;
              onRotate(currentRotation + 90);
            }}
          >
            <RotateRightIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Centralizar">
          <IconButton size="small" onClick={centralizarElemento}>
            <CenterFocusStrongIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Ajustar ao conteúdo">
          <IconButton size="small" onClick={ajustarTamanhoAoConteudo}>
            <FitScreenIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Duplicar elemento">
          <IconButton
            size="small"
            onClick={() => {
              const novoElemento = {
                ...elemento,
                id: `elemento-${Date.now()}`,
                posicao: {
                  x: snapToGrid(elemento.posicao.x + 10),
                  y: snapToGrid(elemento.posicao.y + 10),
                },
              };
              // Dispara um evento personalizado para adicionar o novo elemento
              const event = new CustomEvent("duplicarElemento", {
                detail: { elemento: novoElemento, focus: true },
              });
              window.dispatchEvent(event);
            }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const handleDragStart = () => {
    setIsDragging(true);
    setShowGuides(true);
  };

  const handleDrag = (_e: any, data: { x: number; y: number }) => {
    if (containerRef.current && elementRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const elementRect = elementRef.current.getBoundingClientRect();

      // Atualiza a posição do guia
      setGuidePosition({
        x: snapToGrid(data.x),
        y: snapToGrid(data.y),
      });

      // Verifica se o elemento está próximo das bordas
      const isNearLeft = Math.abs(data.x) < gridSize;
      const isNearRight =
        Math.abs(containerRect.width - (data.x + elementRect.width)) < gridSize;
      const isNearTop = Math.abs(data.y) < gridSize;
      const isNearBottom =
        Math.abs(containerRect.height - (data.y + elementRect.height)) <
        gridSize;

      // Se estiver próximo de alguma borda, ajusta a posição
      if (isNearLeft) {
        data.x = 0;
      } else if (isNearRight) {
        data.x = containerRect.width - elementRect.width;
      }
      if (isNearTop) {
        data.y = 0;
      } else if (isNearBottom) {
        data.y = containerRect.height - elementRect.height;
      }
    }
  };

  const handleDragStop = (_e: any, data: { x: number; y: number }) => {
    setIsDragging(false);
    setShowGuides(false);
    onDragEnd(snapToGrid(data.x), snapToGrid(data.y));
  };

  const handleMouseDown = (e: React.MouseEvent, direction: string) => {
    e.stopPropagation();
    resizing.current = true;
    startPos.current = { x: e.clientX, y: e.clientY };
    startSize.current = {
      width: elementRef.current?.offsetWidth || 0,
      height: elementRef.current?.offsetHeight || 0,
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!resizing.current) return;

      const deltaX = (e.clientX - startPos.current.x) / zoom;
      const deltaY = (e.clientY - startPos.current.y) / zoom;

      let newWidth = startSize.current.width;
      let newHeight = startSize.current.height;
      let newX = elemento.posicao.x;
      let newY = elemento.posicao.y;

      if (direction.includes("e")) {
        newWidth += deltaX;
      }
      if (direction.includes("w")) {
        newWidth -= deltaX;
        newX += deltaX;
      }
      if (direction.includes("s")) {
        newHeight += deltaY;
      }
      if (direction.includes("n")) {
        newHeight -= deltaY;
        newY += deltaY;
      }

      // Primeiro atualiza a posição para manter o ponto de referência correto
      if (direction.includes("w") || direction.includes("n")) {
        onDragEnd(snapToGrid(newX), snapToGrid(newY));
      }

      // Depois atualiza o tamanho
      onResize(
        snapToGrid(Math.max(20, newWidth)),
        snapToGrid(Math.max(20, newHeight))
      );
    };

    const handleMouseUp = () => {
      resizing.current = false;
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const renderResizeHandles = () => {
    const handles = ["n", "e", "s", "w", "ne", "se", "sw", "nw"];
    return handles.map((direction) => (
      <Box
        key={direction}
        sx={{
          position: "absolute",
          width: "8px",
          height: "8px",
          backgroundColor: "#1976d2",
          borderRadius: "50%",
          ...(direction.includes("n") && { top: "-4px" }),
          ...(direction.includes("s") && { bottom: "-4px" }),
          ...(direction.includes("e") && { right: "-4px" }),
          ...(direction.includes("w") && { left: "-4px" }),
          ...(direction === "n" && { left: "calc(50% - 4px)" }),
          ...(direction === "s" && { left: "calc(50% - 4px)" }),
          ...(direction === "e" && { top: "calc(50% - 4px)" }),
          ...(direction === "w" && { top: "calc(50% - 4px)" }),
          cursor:
            direction === "n"
              ? "ns-resize"
              : direction === "s"
              ? "ns-resize"
              : direction === "e"
              ? "ew-resize"
              : direction === "w"
              ? "ew-resize"
              : direction === "ne"
              ? "nesw-resize"
              : direction === "se"
              ? "nwse-resize"
              : direction === "sw"
              ? "nesw-resize"
              : "nwse-resize",
          zIndex: 2,
          "&:hover": {
            transform: "scale(1.2)",
          },
        }}
        onMouseDown={(e) => handleMouseDown(e, direction)}
      />
    ));
  };

  const renderGuides = () => {
    if (!showGuides || !containerRef.current) return null;

    return (
      <>
        {/* Guia horizontal */}
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: guidePosition.y,
            width: "100%",
            height: "1px",
            backgroundColor: "#1976d2",
            pointerEvents: "none",
            zIndex: 1,
          }}
        />
        {/* Guia vertical */}
        <Box
          sx={{
            position: "absolute",
            left: guidePosition.x,
            top: 0,
            width: "1px",
            height: "100%",
            backgroundColor: "#1976d2",
            pointerEvents: "none",
            zIndex: 1,
          }}
        />
      </>
    );
  };

  const renderConteudo = () => {
    if (!produtoExemplo) {
      switch (elemento.tipo) {
        case "texto":
          return (
            <Typography style={{ ...elemento.estilo, color: textColor }}>
              Texto Exemplo
            </Typography>
          );
        case "codigoBarras":
          return (
            <Typography
              style={{
                background: "#ccc",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: textColor,
              }}
            >
              Código de Barras
            </Typography>
          );
        case "preco":
          return (
            <Typography style={{ ...elemento.estilo, color: textColor }}>
              R$ 99,99
            </Typography>
          );
        case "textoCodigoBarras":
          return (
            <Typography style={{ ...elemento.estilo, color: textColor }}>
              codigo de barras
            </Typography>
          );
        case "codigoProduto":
          return (
            <Typography style={{ ...elemento.estilo, color: textColor }}>
              codigo de produto
            </Typography>
          );
        default:
          return null;
      }
    }

    switch (elemento.tipo) {
      case "texto":
        return (
          <Typography style={{ ...elemento.estilo, color: textColor }}>
            {produtoExemplo.produto_nome}
          </Typography>
        );
      case "codigoBarras":
        return (
          <img
            src={textToBase64Barcode(produtoExemplo.cod_barra || "")}
            alt="Código de Barras"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
              display: "block",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            draggable={false}
          />
        );
      case "codigoBarrasSemTexto":
        return (
          <img
            src={textToBase64Barcode(
              produtoExemplo.cod_barra || "",
              12,
              40,
              false
            )}
            alt="Código de Barras"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
              display: "block",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            draggable={false}
          />
        );
      case "textoCodigoBarras":
        return (
          <Typography style={{ ...elemento.estilo, color: textColor }}>
            {produtoExemplo.cod_barra}
          </Typography>
        );
      case "codigoProduto":
        return (
          <Typography style={{ ...elemento.estilo, color: textColor }}>
            {produtoExemplo.produto?.codigo}
          </Typography>
        );
      case "preco":
        return (
          <Typography style={{ ...elemento.estilo, color: textColor }}>
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(produtoExemplo.preco_venda || 0)}
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderGuides()}
      <Draggable
        bounds="parent"
        handle=".drag-handle"
        position={{
          x: snapToGrid(elemento.posicao.x),
          y: snapToGrid(elemento.posicao.y),
        }}
        grid={[gridSize, gridSize]}
        onStart={handleDragStart}
        onDrag={handleDrag}
        onStop={handleDragStop}
      >
        <Box
          ref={elementRef}
          className="drag-handle"
          data-element-id={elemento.id}
          sx={{
            position: "absolute",
            width: snapToGrid(elemento.tamanho?.width || 100),
            height: snapToGrid(elemento.tamanho?.height || 30),
            cursor: "move",
            padding: "4px",
            border: selecionado ? "2px solid #1976d2" : "1px dashed #ccc",
            backgroundColor: "white",
            userSelect: "none",
            transition: isDragging ? "none" : "all 0.2s",
            boxShadow: isDragging ? "0 0 10px rgba(0,0,0,0.2)" : "none",
            "&:hover": {
              borderColor: "#1976d2",
              boxShadow: "0 0 5px rgba(0,0,0,0.1)",
            },
            transform: isDragging
              ? "scale(1.02)"
              : `rotate(${elemento.rotacao || 0}deg)`,
            transformOrigin: "center center",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          <Box
            ref={contentRef}
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transform: `rotate(${elemento.rotacao || 0}deg)`,
              transformOrigin: "center center",
            }}
          >
            {renderConteudo()}
          </Box>
          {renderControles()}
          {selecionado && renderResizeHandles()}
        </Box>
      </Draggable>
    </>
  );
};

export default ElementoEtiqueta;
