/* eslint-disable @typescript-eslint/no-explicit-any */
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelMultipleAutocomplete from "components/nuvel/MultipleAutocomplete";
import NuvelSelect from "components/nuvel/Select";
import DefaultRelatorios from "components/retaguarda/relatorios";
import dayjs from "dayjs";
import React from "react";
import { handleChange } from "utils/functions";

interface ProdutosState {
  relatorio: number;
  data_inicio: dayjs.Dayjs;
  data_fim: dayjs.Dayjs;
  deposito: number | undefined;
  tags: number[];
  produtos: number[];
  classificacao: number[];
}

const formatTipoToStr = (tipo: number): string => {
  switch (tipo) {
    case 0:
      return "Estoque Inicial";
    case 2:
      return "Avaria / Transferência";
    case 3:
      return "Entrada de Nota";
    case 4:
      return "Saída de Mercadoria";
    case 5:
      return "Ajuste de Estoque";
    case 6:
      return "Saída de Mercadoria por Composição";
    case 7:
      return "Cancelamento de Venda";
    case 8:
      return "Cancelamento de Venda por Composição";
    default:
      return "";
  }
};

const filtros = (state: ProdutosState) => ({
  data_inicio: dayjs(state.data_inicio).format(),
  data_fim: dayjs(state.data_fim).endOf("day").format(),
  filtro_deposito: state.deposito,
  tags: state.tags.join(","),
  produtos: state.produtos.join(","),
  classificacao: state.classificacao.join(","),
});

const defaultUrl = "produto";

const relatorios = (state: ProdutosState) => [
  {
    url: `${defaultUrl}/posicao_atual_estoque`,
    title: "Relatório de Posição Atual de Estoque",
    columns: [
      { label: "Produto", accessor: "produto", flex: 6 },
      {
        label: "Quantidade Atual",
        accessor: "quantidade",
        flex: 2,
      },
    ],
    totais: "quantidade",
    filtros: filtros(state),
  },
  {
    url: `${defaultUrl}/estoque_critico`,
    title: "Relatório de Estoque Crítico",
    columns: [
      { label: "Produto", accessor: "produto", flex: 6 },
      {
        label: "Quantidade Atual",
        accessor: "quantidade",
        flex: 2,
      },
      {
        label: "Estoque Mínimo",
        accessor: "estoque_minimo",
        flex: 2,
      },
    ],
    totais: "quantidade",
    filtros: filtros(state),
  },
  {
    url: `${defaultUrl}/movimentacoes_estoque`,
    title: "Relatório de Movimentações de Estoque",
    columns: [
      { label: "Produto", accessor: "produto", flex: 6 },
      {
        label: "Tipo",
        accessor: (row: any) => formatTipoToStr(row.tipo),
        flex: 2,
      },
      {
        label: "Data",
        accessor: (row: any) =>
          dayjs(row.data_inclusao).format("DD/MM/YYYY HH:mm:ss"),
        flex: 2,
      },
      {
        label: "Quantidade",
        accessor: "quantidade",
        flex: 2,
      },
    ],
    totais: "quantidade",
    filtros: filtros(state),
  },
  {
    url: `${defaultUrl}/relacao_codigo_barras`,
    title: "Relação de Códigos de Barras",
    columns: [
      { label: "Produto", accessor: "produto", flex: 6 },
      { label: "Código de Barras", accessor: "codbarra", flex: 6 },
      {
        label: "Preço Custo",
        accessor: "preco_custo",
        type: "money",
        flex: 2,
      },
      {
        label: "Preço Venda",
        accessor: "preco_venda",
        type: "money",
        flex: 2,
      },
    ],
    totais: "preco_custo,preco_venda",
    filtros: filtros(state),
  },
  {
    url: `${defaultUrl}/relacao_produtos`,
    title: "Relação de Produtos",
    columns: [
      { label: "Produto", accessor: "produto", flex: 6 },
      { label: "Código", accessor: "codigo", flex: 3 },
      { label: "Códigos de Barras", accessor: "codbarras__codbarra", flex: 3 }, // Alterado aqui
      {
        label: "Preço Custo",
        accessor: "preco_custo",
        type: "money",
        flex: 2,
      },
      {
        label: "Preço Venda",
        accessor: "preco_venda",
        type: "money",
        flex: 2,
      },
    ],
    totais: "preco_custo,preco_venda",
    filtros: filtros(state),
  },
  {
    url: `${defaultUrl}/pereciveis`,
    title: "Relatório de Perecíveis",
    columns: [
      { label: "ID", accessor: "id", flex: 1 },
      { label: "Nome", accessor: "nome", flex: 1 },
      {
        label: "Vencimento",
        accessor: "data_validade",
        flex: 2,
        type: "date",
      },
      {
        label: "Quantidade",
        accessor: "quantidade",
        flex: 2,
        type: "number",
      },
    ],
    totais: "quantidade",
    filtros: filtros(state),
  },
  {
    url: `${defaultUrl}/relatorio_validade`,
    title: "Relatório de Validade de Produtos",
    columns: [
      { label: "Produto", accessor: "produto", flex: 4 },
      {
        label: "Data de Validade",
        accessor: (row: any) => dayjs(row.data_validade).format("DD/MM/YYYY"),
        flex: 2,
        type: "date",
      },
      { label: "Quantidade", accessor: "quantidade", flex: 1, type: "number" },
      { label: "Depósito", accessor: "deposito", flex: 2 },
      {
        label: "Data de Entrada",
        accessor: (row: any) => dayjs(row.data_entrada).format("DD/MM/YYYY"),
        flex: 2,
        type: "date",
      },
      {
        label: "Número da Nota de Entrada",
        accessor: "numero_nota_entrada",
        flex: 2,
      },
    ],
    totais: "quantidade",
    filtros: filtros(state),
  },
];

const RetReportProdutosForm: React.FC = () => {
  const [state, setState] = React.useState<ProdutosState>({
    relatorio: 0,
    data_inicio: dayjs().subtract(6, "months"),
    data_fim: dayjs().add(6, "months"),
    deposito: undefined,
    tags: [],
    produtos: [],
    classificacao: [],
  });

  return (
    <DefaultRelatorios
      title="Produtos"
      relatorios={relatorios(state)}
      relatorio={state.relatorio}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={4}>
          <NuvelSelect
            label="Relatório"
            name="relatorio"
            onChange={(e) => handleChange(e, setState)}
            value={state.relatorio}
            options={relatorios(state).map((rel, index) => ({
              value: index,
              label: rel.title,
            }))}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelDatePicker
            label="Data Início"
            onChange={(e) => handleChange(e, setState)}
            name="data_inicio"
            value={state.data_inicio}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelDatePicker
            label="Data Fim"
            onChange={(e) => handleChange(e, setState)}
            name="data_fim"
            value={state.data_fim}
          />
        </NuvelGrid>
        <NuvelGrid xs={3}>
          <NuvelAutocomplete
            label="Depósito"
            name="deposito"
            reference="deposito"
            value={state.deposito}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid xs={3}>
          <NuvelMultipleAutocomplete
            label="Produto"
            name="produtos"
            value={state.produtos}
            onChange={(_, v) => setState({ ...state, produtos: v as number[] })}
            filter={{
              estoques__deposito: state.deposito,
            }}
            disabled={!state.deposito}
            reference="produto"
          />
        </NuvelGrid>
        <NuvelGrid xs={3}>
          <NuvelMultipleAutocomplete
            name="tags"
            reference="tags"
            label="Tags"
            value={state.tags}
            onChange={(_, v) => setState({ ...state, tags: v as number[] })}
          />
        </NuvelGrid>
        <NuvelGrid xs={3}>
          <NuvelSelect
            label="Classificação"
            value={state.classificacao}
            name="classificacao"
            onChange={(e) =>
              setState({ ...state, classificacao: e.target.value as number[] })
            }
            options={[
              { label: "00 - Mercadoria para Revenda", value: 0 },
              { label: "01 - Materia-Prima", value: 1 },
              { label: "02 - Embalagem", value: 2 },
              { label: "03 - Produto em Processo", value: 3 },
              { label: "04 - Produto Acabado", value: 4 },
              { label: "05 - Subproduto", value: 5 },
              { label: "06 - Produto Intermediario", value: 6 },
              { label: "07 - Material de Uso e Consumo", value: 7 },
              { label: "08 - Ativo Imobilizado", value: 8 },
              { label: "09 - Combustível", value: 9 },
              { label: "10 - Outros Insumos", value: 10 },
              { label: "99 - Outros", value: 11 },
            ]}
            multiple
          />
        </NuvelGrid>
      </NuvelGrid>
    </DefaultRelatorios>
  );
};

export default RetReportProdutosForm;
