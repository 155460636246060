/* eslint-disable @typescript-eslint/no-explicit-any */
import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelMultipleAutocomplete from "components/nuvel/MultipleAutocomplete";
import NuvelSelect from "components/nuvel/Select";
import DefaultRelatorios from "components/retaguarda/relatorios";
import dayjs from "dayjs";
import React from "react";
import { handleChange } from "utils/functions";

interface RelatorioCaixaState {
  relatorio: number;
  data_inicio: dayjs.Dayjs;
  data_fim: dayjs.Dayjs;
  lojas: number[];
  tags: number[];
  tipo_operacao: number;
}

const tipo_operacoes = [
  { value: 0, label: "Todos" },
  { value: 1, label: "Sangria" },
  { value: 2, label: "Suprimento" },
  { value: 3, label: "Despesa" },
  { value: 4, label: "Vale Funcionário Crédito" },
  { value: 5, label: "Vale Funcionário Débito" },
];

const relatorios = (state: RelatorioCaixaState) => [
  {
    url: "caixa/folha_de_caixa",
    title: "Relatório de Folha de Caixa Sintético",
    columns: [
      { label: "Forma de Pagamento", accessor: "forma", flex: 6 },
      { label: "Valor", accessor: "valor", flex: 2, type: "money" },
    ],
    totais: "valor",
    filtros: {
      data_inicio: dayjs(state.data_inicio).format(),
      data_fim: dayjs(state.data_fim).endOf("day").format(),
      lojas: state.lojas.join(","),
      tags: state.tags.join(","),
    },
  },
  {
    url: "caixa/folha_de_caixa_analitico",
    title: "Relatório de Folha de Caixa Analítico",
    columns: [{ label: "Funcionário", accessor: "usuario", flex: 6 }],
    childs: [
      {
        label: "Pagamentos",
        key: "child",
        columns: [
          { label: "Forma de Pagamento", accessor: "forma", flex: 6 },
          { label: "Valor", accessor: "valor", flex: 2, type: "money" },
        ],
      },
    ],
    totais: "valor",
    filtros: {
      data_inicio: dayjs(state.data_inicio).format(),
      data_fim: dayjs(state.data_fim).endOf("day").format(),
      lojas: state.lojas.join(","),
      tags: state.tags.join(","),
    },
  },
  {
    url: "caixa/operacoes_de_caixa",
    title: "Relatório de Operações de Caixa",
    columns: [
      { label: "Descrição", accessor: "descricao", flex: 6 },
      { label: "Operador", accessor: "usuario", flex: 2 },
      { label: "Data", accessor: "data", flex: 2, type: "date" },
      {
        label: "Operação",
        accessor: (row: any) => {
          const tipo = tipo_operacoes.find((tipo) => tipo.value === row.tipo);
          return tipo ? tipo.label : "";
        },
        flex: 2,
      },
      { label: "Valor", accessor: "valor", flex: 2, type: "money" },
    ],
    totais: "valor",
    filtros: {
      data_inicio: dayjs(state.data_inicio).format(),
      data_fim: dayjs(state.data_fim).endOf("day").format(),
      lojas: state.lojas.join(","),
      tags: state.tags.join(","),
      tipo_operacao: state.tipo_operacao,
    },
  },
  {
    url: "caixa/caixas",
    title: "Relatório de Caixas",
    columns: [
      {
        label: "Troco inicial",
        accessor: "troco_inicial",
        flex: 2,
        type: "money",
      },
      { label: "Troco final", accessor: "troco_final", flex: 2, type: "money" },
      {
        label: "Data de abertura",
        accessor: (row: any) =>
          dayjs(row.data_abertura).format("DD/MM/YYYY HH:mm"),
        flex: 2,
      },
      {
        label: "Data de fechamento",
        accessor: (row: any) =>
          dayjs(row.data_fechamento).format("DD/MM/YYYY HH:mm"),
        flex: 2,
      },
      { label: "Funcionário", accessor: "funcionario", flex: 2 },
      { label: "Suprimento", accessor: "suprimento", flex: 2, type: "money" },
      { label: "Sangria", accessor: "sangria", flex: 2, type: "money" },
      { label: "Subtotal", accessor: "subtotal", flex: 2, type: "money" },
      {
        label: "Total",
        accessor: (row: any) =>
          (
            row.troco_inicial +
            row.subtotal +
            row.suprimento -
            row.sangria
          ).toBRL(),
        flex: 2,
        type: "money",
      },
    ],
    filtros: {
      data_inicio: dayjs(state.data_inicio).format(),
      data_fim: dayjs(state.data_fim).endOf("day").format(),
      lojas: state.lojas.join(","),
      tags: state.tags.join(","),
    },
  },
];

const RetReportCaixaForm: React.FC = () => {
  const [state, setState] = React.useState<RelatorioCaixaState>({
    relatorio: 0,
    data_inicio: dayjs().startOf("day"),
    data_fim: dayjs().endOf("day"),
    lojas: [],
    tags: [],
    tipo_operacao: 0,
  });

  return (
    <DefaultRelatorios
      title="Caixa"
      relatorio={state.relatorio}
      relatorios={relatorios(state)}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={4}>
          <NuvelSelect
            label="Relatório"
            name="relatorio"
            onChange={(e) => handleChange(e, setState)}
            value={state.relatorio}
            options={relatorios(state).map((rel, index) => ({
              value: index,
              label: rel.title,
            }))}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelDatePicker
            label="Data Início"
            onChange={(e) => handleChange(e, setState)}
            name="data_inicio"
            value={state.data_inicio}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelDatePicker
            label="Data Fim"
            onChange={(e) => handleChange(e, setState)}
            name="data_fim"
            value={state.data_fim}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelMultipleAutocomplete
            name="loja"
            reference="loja"
            label="Lojas"
            value={state.lojas}
            onChange={(_, v) => setState({ ...state, lojas: v as number[] })}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelMultipleAutocomplete
            name="tags"
            reference="tags"
            label="Tags"
            value={state.tags}
            onChange={(_, v) => setState({ ...state, tags: v as number[] })}
          />
        </NuvelGrid>
        {relatorios(state)[state.relatorio].url ===
          "caixa/operacoes_de_caixa" && (
          <NuvelGrid xs={4}>
            <NuvelSelect
              label="Tipo de Operação"
              name="tipo_operacao"
              onChange={(e) => handleChange(e, setState)}
              value={state.tipo_operacao}
              options={tipo_operacoes.map((tipo) => ({
                value: tipo.value,
                label: tipo.label,
              }))}
            />
          </NuvelGrid>
        )}
      </NuvelGrid>
    </DefaultRelatorios>
  );
};

export default RetReportCaixaForm;
